import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import { Block } from '../../../components/Component';
import TablePageHeading from '../../dashboard_pages/components/page_heading/TablePageHeading';
import CardTabs from '../../dashboard_pages/components/cards/CardTabs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RETRIEVE_CLIENTS_DELIVERY_NOTES_LIST_API } from '../../../api/api_data';
import { RETRIEVE_DELIVERY_NOTES_TICKETS_ACCESS_DENIED_MSG, RETRIEVE_DELIVERY_NOTES_TICKETS_ERR_MSG, RETRIEVE_DELIVERY_NOTES_TICKETS_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { fail_retrieve_delivery_notes_list, loading_retrieve_delivery_notes_list, reset_retrieve_delivery_notes_list, success_retrieve_delivery_notes_list } from '../../../reducers/client_reducers/delivery_notes/retrieve_delivery_notes_list_Slice';
import * as app_consts from "../../../constants/react_app_consts";
import { client_actions_helper } from '../../../actions/actions_helper';
import { messageToast } from '../../../utils/Utils';
import { Spinner } from 'reactstrap';
import TableList from '../../dashboard_pages/components/table/TableList';

const type = app_consts.CLIENT_DELIVERY_NOTES_LIST;

const DeliverynotesList = ({ account_type }) => {

    const dispatch = useDispatch();

    const [page_data, setPageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [activeCardTab, setActiveCardTab] = useState("Processing");
    const [dataFetched, setDataFetched] = useState(false);
    const [fully_processed_data, setFullyProcessedData] = useState([]);
    const [partially_processed_data, setPartiallyProcessedData] = useState([]);
    const [in_transite_data, setInTransitData] = useState([]);
    const [delivered_data, setDeliveredData] = useState([]);

    useEffect(() => {
        retrieve_delivery_notes_list_func();
    }, []);

    const log_cred = useSelector((state) => state.logged_in_cred.logged_in_cred);

    // Retrieve Goods Received List for Client
    const retrieve_delivery_notes_list_func = () => {
        setError("");
        dispatch(
            client_actions_helper(
                loading_retrieve_delivery_notes_list,
                success_retrieve_delivery_notes_list,
                fail_retrieve_delivery_notes_list,
                RETRIEVE_DELIVERY_NOTES_TICKETS_ERR_MSG,
                RETRIEVE_DELIVERY_NOTES_TICKETS_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_CLIENTS_DELIVERY_NOTES_LIST_API,
                null,
                RETRIEVE_DELIVERY_NOTES_TICKETS_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to Reducer
    const retrieve_delivery_notes_list = useSelector((state) => state.retrieve_delivery_notes_list.retrieve_delivery_notes_list);
    // 
    useEffect(() => {
        if (retrieve_delivery_notes_list.status !== app_consts.IDLE)
        {
            if (retrieve_delivery_notes_list.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (retrieve_delivery_notes_list.status === app_consts.FAIL)
                {
                    setError(retrieve_delivery_notes_list.message);
                    messageToast(retrieve_delivery_notes_list.message, app_consts.FAIL);
                } else if (retrieve_delivery_notes_list.status === app_consts.SUCCESS)
                {
                    setPageData(retrieve_delivery_notes_list.data);
                }
                dispatch(reset_retrieve_delivery_notes_list());
            }
        }
    }, [retrieve_delivery_notes_list]);

    // Refresh List Function
    const refreshList = () => {
        retrieve_delivery_notes_list_func();
        setPageData([]);
    };

    // Listen to Reducer

    // Card Tabs
    const tabs = [
        {
            tabName: "Processing", icon: "user-circle"
        },
        // {
        //     tabName: "Awaiting Transit", icon: "user-circle"
        // },
        {
            tabName: "In Transit", icon: "user-circle"
        },
        {
            tabName: "Delivered", icon: "user-circle"
        },
    ];

    // UseEffect to Filter Page Data
    useEffect(() => {
        if (page_data)
        {
            const complete_data = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE);

            const uncomplete_data = page_data?.filter((complete) =>
                complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_ONBOARDING_GOODS || complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_OUTBOUND
            );

            const transit_data = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_IN_TRANSIT);

            const deliveredData = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_DELIVERED);

            setFullyProcessedData(complete_data);
            setPartiallyProcessedData(uncomplete_data);
            setInTransitData(transit_data);
            setDeliveredData(deliveredData);
        }
    }, [page_data]);

    return (
        <>
            <Head title="Delivery Note Ticket List" />
            <Content>
                <Block className="items-center w-80 m-auto">
                    {/* Table Page Heading */}
                    <TablePageHeading
                        title="Delivery Notes Tickets List"
                        description={`Total Delivery Notes Tickets: ${page_data.length}`}
                        refresh={!loading}
                        refreshFunc={refreshList}
                        title_addition={log_cred?.data?.username}
                        backToText="Home"
                        backToLink="/customer"
                    //   csv_export
                    //   add_to_list={
                    //     componentData.create_goods_received_weigh_bridge_ticket ||
                    //     componentData.create_delivery_note_weigh_bridge_ticket
                    //   }
                    //   componentData={componentData}
                    //   page_type={type}
                    //   pageData={page_data}
                    //   openAddList={openAddListModal}
                    />
                    {
                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                        {error}
                                    </div>
                                </>
                                :
                                <>
                                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                                    {
                                        activeCardTab === "Processing" && (
                                            <>
                                                <TableList
                                                    // left_head_select
                                                    type={type}
                                                    data={partially_processed_data}
                                                //   rowOptionsData={componentData}
                                                // amendFunc={amend_Account_Status_modal}
                                                // setOpendModalDetails={setOpendModalDetails}
                                                // setSelectedData={setSelectedUser}
                                                />
                                            </>
                                        )
                                    }
                                    {/* {
                                        activeCardTab === "Awaiting Transit" && (
                                            <>
                                                <TableList
                                                    // left_head_select
                                                    type={type}
                                                    data={fully_processed_data}
                                                //   rowOptionsData={componentData} 
                                                // amendFunc={amend_Account_Status_modal}
                                                // setOpendModalDetails={setOpendModalDetails}
                                                // setSelectedData={setSelectedUser}
                                                />
                                            </>
                                        )
                                    } */}
                                    {
                                        activeCardTab === "In Transit" && (
                                            <>
                                                <TableList
                                                    // left_head_select
                                                    type={type}
                                                    data={in_transite_data}
                                                // rowOptionsData={componentData}
                                                // amendFunc={amend_Account_Status_modal}
                                                // setOpendModalDetails={setOpendModalDetails}
                                                // setSelectedData={setSelectedUser}
                                                />
                                            </>
                                        )
                                    }
                                    {
                                        activeCardTab === "Delivered" && (
                                            <>
                                                <TableList
                                                    // left_head_select
                                                    type={type}
                                                    data={delivered_data}
                                                // rowOptionsData={componentData}
                                                // amendFunc={amend_Account_Status_modal}
                                                // setOpendModalDetails={setOpendModalDetails}
                                                // setSelectedData={setSelectedUser}
                                                />
                                            </>
                                        )
                                    }
                                </>
                    }
                </Block>
            </Content>
        </>
    );
};

export default DeliverynotesList;