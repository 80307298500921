import { makeClientApiRequest, makeUnAuthApiRequest, makeEmployeeDashboardApiRequest } from "../api/api_helper";
import { success_sign_out_modal } from "../reducers/client_reducers/auth/sign_out_modal_Slice";
import { messageToast, rSelectMapFun } from "../utils/Utils";
import * as app_consts from "../constants/react_app_consts";
import { map_system_actions, retreive_rSelectVariables } from "../utils/functionalUtils";

export const client_actions_helper =
  (loadingState, successState, failState, errorMessage, successMessage, apiReqType, apiEndpoint, formData = null, accessDeniedMessage = null) =>
    async (dispatch) => {
      try
      {
        //Set Loading
        dispatch(loadingState());
        //Making API Request
        const { data } = await makeClientApiRequest(apiReqType, apiEndpoint, formData);
        if (data.message === successMessage)
        {
          dispatch(successState(data.data));
        }
      } catch (error)
      {
        console.log(error);
        // Server or Network Error
        if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE")
        {
          dispatch(failState(errorMessage));
        } else
        {
          // Bad Response Message
          if (error.response.status === 400 || error.response.status === 404 || error.response.status === 403)
          {
            dispatch(failState(error.response.data.message));
          }
          // Unauthorized
          else if (error.response.status === 401)
          {
            dispatch(success_sign_out_modal());
          }
          //  404
          else if (error.response.status === 404)
          {
            messageToast(accessDeniedMessage, app_consts.FAIL);
            dispatch(failState(accessDeniedMessage));
          }
        }
      }
    };


export const employee_dashboard_actions_helper =
  (
    loadingState,
    successState,
    failState,
    errorMessage,
    successMessage,
    apiReqType,
    apiEndpoint,
    formData = null,
    accessDeniedMessage = null,
    res_action = null,
    res_type = null
  ) =>
    async (dispatch) => {
      try
      {
        //Set Loading
        dispatch(loadingState());
        //Making API Request
        const { data } = await makeEmployeeDashboardApiRequest(apiReqType, apiEndpoint, formData);
        if (data.message === successMessage)
        {
          if (res_action === app_consts.MAP)
          {
            // Map Received Data
            if (res_type === app_consts.AUTH_ROUTES_MAP)
            {
              let mappedData = {};

              await Promise.all(
                data.data?.map(async (data_to_map) => {
                  const mappedResult = await map_system_actions(data_to_map);
                  mappedData[data_to_map.module_name] = mappedResult;
                })
              );
              dispatch(successState(mappedData));
            } else
            {
              let mappedData = [];

              await Promise.all(
                data.data?.map(async (data_to_map) => {
                  const { label, value } = retreive_rSelectVariables(res_type, data_to_map);
                  const result = await rSelectMapFun(label, value);
                  mappedData.push(result);
                })
              );

              dispatch(successState(mappedData));
            }
          } else
          {
            dispatch(successState(data.data));
          }
        }
      } catch (error)
      {
        console.log(error);
        // Server or Network Error
        if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE")
        {
          dispatch(failState(errorMessage));
        } else
        {
          // Bad Response Message
          if (error.response.status === 400 || error.response.status === 404)
          {
            dispatch(failState(error.response.data.message));
          }
          // Unauthorized
          else if (error.response.status === 401)
          {
            dispatch(success_sign_out_modal());
          }
          // Access Denied
          else if (error.response.status === 403)
          {
            messageToast(accessDeniedMessage, app_consts.FAIL);
            dispatch(failState(accessDeniedMessage));
          }
        }
      }
    };

export const website_actions_helper =
  (loadingState, successState, failState, errorMessage, successMessage, apiReqType, apiEndpoint, formData = null) =>
    async (dispatch) => {
      try
      {
        //Set Loading
        dispatch(loadingState());
        //Making API Request
        const { data } = await makeUnAuthApiRequest(apiReqType, apiEndpoint, formData);
        if (data.message === successMessage)
        {
          dispatch(successState(data.data));
        }
      } catch (error)
      {
        console.log(error);
        // Server or Network Error
        if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE")
        {
          dispatch(failState(errorMessage));
        } else
        {
          // Bad Response Message
          if (error.response.status === 400)
          {
            dispatch(failState(error.response.data.message));
          }
        }
      }
    };