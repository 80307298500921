import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import { Block, BlockHead, BlockTitle } from '../../../components/Component';
import PageHeading from '../components/page_heading/PageHeading';
import { useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import CardTabs from '../components/cards/CardTabs';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { fail_retrieve_warehouse_inventory_product_details, loading_retrieve_warehouse_inventory_product_details, reset_retrieve_warehouse_inventory_product_details, success_retrieve_warehouse_inventory_product_details } from '../../../reducers/dashboard_reducers/warehouse/retrieve_warehouse_inventory_product_details_Slice';
import { RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_API } from '../../../api/api_data';
import { RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_ACCESS_DENIED_MSG, RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_ERR_MSG, RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import * as app_consts from "../../../constants/react_app_consts";
import { messageToast } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import ProfileDetailText from '../components/text/ProfileDetailText';
import moment from 'moment';
import ReadOnlyTable from '../components/table/ReadOnlyTable';
import WarehouseInventoryModel from '../components/modal/warehouse_inventory_modal/WarehouseInventoryModel';

const warehouse_list_type = app_consts.WAREHOUSE_INVENTORY_BRANCHES_LIST;
const inventory_history_list_type = app_consts.INVENTORY_HISTORY_LIST;

const page_type = app_consts.PRODUCT_WAREHOUSE_INVENTORY_PAGE;

const WarehouseInventoryProduct = () => {

    const { product_id } = useParams();
    const dispatch = useDispatch();
    const [activeCardTab, setActiveCardTab] = useState("Summary");
    const [dataFetched, setDataFetched] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [page_data, setPageData] = useState([]);
    const [warehouse_list_data, setWarehouseListData] = useState([]);
    const [inventory_history_data, setInventorylistData] = useState([]);
    const [product_name, setProductName] = useState("");
    const [isInventoryModuleOpen, setInventoryModalOpen] = useState(false);
    const [selectedAdjustID, setSelectedAdjustID] = useState("");

    // UseEffect to Run When the Page Opens
    useEffect(() => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_retrieve_warehouse_inventory_product_details,
                success_retrieve_warehouse_inventory_product_details,
                fail_retrieve_warehouse_inventory_product_details,
                RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_ERR_MSG,
                RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_SUCCESS_MSG,
                app_consts.POST,
                RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_API,
                { product_id: product_id },
                RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_ACCESS_DENIED_MSG
            )
        );
    }, []);

    // Listen to Reducer
    const warehouse_inventory_product_details = useSelector((state) => state.retrieve_warehouse_inventory_product_details.retrieve_warehouse_inventory_product_details);
    // 
    useEffect(() => {
        if (warehouse_inventory_product_details.status !== app_consts.IDLE)
        {
            if (warehouse_inventory_product_details.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (warehouse_inventory_product_details.status === app_consts.FAIL)
                {
                    setError(warehouse_inventory_product_details.message);
                    messageToast(warehouse_inventory_product_details.message, app_consts.FAIL);
                } else if (warehouse_inventory_product_details.status === app_consts.SUCCESS)
                {
                    setPageData(warehouse_inventory_product_details.data);
                    setWarehouseListData(warehouse_inventory_product_details.data?.branch_locations);
                    restructure_inventory_list_data(warehouse_inventory_product_details.data?.inventory);
                    setDataFetched(true);
                }
                dispatch(reset_retrieve_warehouse_inventory_product_details());
            }
        }
    }, [warehouse_inventory_product_details]);

    const restructure_inventory_list_data = (inventory) => {

        const calculateBalance = () => {
            let balance = 0; // Initial balance
            return inventory?.map((item) => ({
                ...item,
                balance: (balance += item.adjustment_type === "add"
                    ? parseFloat(item.capacity)
                    : -parseFloat(item.capacity)).toFixed(2),
                message: item?.inventory_history[0]?.message
            }));
        };

        setInventorylistData(calculateBalance());
    };

    const pageHeadingActions = (type, data) => {

    };


    // Card Tabs
    const tabs = [
        {
            tabName: "Summary"
        },
        {
            tabName: "Physical Inventory Warehouses"
        },
        {
            tabName: "Inventory History"
        },
    ];

    const adjust_inventory = (warehouse_storage_id) => {
        setInventoryModalOpen(true);
        setSelectedAdjustID(warehouse_storage_id);
    };

    return (
        <>
            <Head title={`Warehouse Inventory Product Details - ${product_name ? product_name?.replace(/_/g, ' ').toUpperCase() : ""}`} />
            <Content>
                <Block>
                    {/* Table Page Heading */}
                    {/* Page Heading */}
                    <PageHeading
                        title="Warehouse Inventory Product Details"
                        title_addition={product_name?.replace(/_/g, ' ').toUpperCase()}
                        description="Details of Product In Warehouse Inventory"
                        backToText="Warehouse Inventory"
                        backToLink={`/dashboard/warehouse_inventory`}
                        // showDropDown
                        type={page_type}
                        pageHeadingActions={pageHeadingActions}
                    />
                    {
                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                        {error}
                                    </div>
                                </>
                                :
                                <>
                                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                                    <div className="card-inner">
                                        {
                                            activeCardTab === "Summary" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Warehouse Inventory Product Summary</BlockTitle>
                                                            <p>A Brief Summary of Product in Inventory.</p>
                                                        </BlockHead>
                                                        <BlockHead>
                                                            <BlockTitle tag="h6">Brief Summary</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Product" text={page_data?.product_name || "---"} />
                                                            <ProfileDetailText label="Product Type" text={page_data?.product_type || "---"} />
                                                            <ProfileDetailText label="Product Status" text={page_data?.product_status || "---"} textClassName={page_data?.product_status_id === app_consts.ACTIVATE_ACCOUNT_ID ? "text-success" : "text-warning"} />
                                                            <ProfileDetailText label="Total Inventory Capacity" text={page_data?.total_warehouse_store ? `${parseFloat(page_data?.total_warehouse_store).toFixed(2)} MT` : "---"} />
                                                            <ProfileDetailText label="Total Physical Capacity" text={page_data?.physical_store_capacity ? `${parseFloat(page_data?.physical_store_capacity).toFixed(2)} MT` : "---"} />
                                                            <ProfileDetailText label="Total Virtual Capacity" text={page_data?.virtual_store_capacity ? `${parseFloat(page_data?.virtual_store_capacity).toFixed(2)} MT` : "---"} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                        {
                                            activeCardTab === "Physical Inventory Warehouses" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Physical Inventory Warehouses</BlockTitle>
                                                            <p>A List of Warehouses Where the Product Can Be Found</p>
                                                        </BlockHead>
                                                        {/* Table */}
                                                        <div className="my-3">
                                                            <ReadOnlyTable data={warehouse_list_data} tableType={warehouse_list_type} action_function={adjust_inventory} />
                                                        </div>
                                                    </Block>
                                                    <WarehouseInventoryModel openModal={isInventoryModuleOpen} setOpenModal={setInventoryModalOpen} type={app_consts.ADJUST_INVENTORY_MODAL} data={{ warehouse_storage_id: selectedAdjustID, product_id: product_id }} />
                                                </>
                                            )
                                        }
                                        {
                                            activeCardTab === "Inventory History" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Inventory History</BlockTitle>
                                                        </BlockHead>
                                                        {/* Table */}
                                                        <div className="my-3">
                                                            <ReadOnlyTable data={inventory_history_data} tableType={inventory_history_list_type} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                    </div>
                                </>
                    }
                </Block>
            </Content>
        </>
    );
};

export default WarehouseInventoryProduct;