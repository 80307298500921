import moment from "moment";
import LabelText from "../../../text/LabelText";

// User Details
export const UserModalDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.first_name} label="First Name" />
      {data?.middle_name && <LabelText text={data?.middle_name} label="Middle Name" />}
      <LabelText text={data?.surname} label="Surname" />
      {data?.id_number && <LabelText text={data?.id_number} label="ID Number" />}
      <LabelText text={data?.phone_number} label="Phone Number" />
      <LabelText text={data?.email_address} label="Email Address" />
      <LabelText
        text={`${data?.branch_physical_address}, ${data?.branch_area}, ${data?.branch_province_name}, ${data?.branch_country_name}`}
        label="Registered Branch Location"
      />
      <LabelText text={moment(data?.registration_date).format("MMMM Do, YYYY h:mm:ss")} label="Registered Date" />
      <LabelText
        text={data?.account_status}
        label="Account Status"
        badgeStat
        badgeColor={
          data?.account_status_id === 1
            ? "success"
            : data?.account_status_id === 2
              ? "warning"
              : data?.account_status_id === 3
                ? "danger"
                : ""
        }
      />
    </>
  );
};

// Branch Location List
export const BranchLocationModalDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.branch_area} label="Branch Area" />
      <LabelText text={data?.branch_physical_address} label="Branch Physical Address" />
      <LabelText text={data?.branch_mobile_number} label="Branch Mobile Number" />
      <LabelText text={data?.province_name} label="Province Name" />
      <LabelText
        text={moment(data?.branch_registered_date).format("MMMM Do, YYYY h:mm:ss")}
        label="Branch Registered Date"
      />
      <LabelText
        text={data?.account_status}
        label="Account Status"
        badgeStat
        badgeColor={
          data?.account_status_id === 1
            ? "success"
            : data?.account_status_id === 2
              ? "warning"
              : data?.account_status_id === 3
                ? "danger"
                : ""
        }
      />
    </>
  );
};

// Product List
export const ProductModalDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.product_name} label="Product Name" />
      <LabelText text={data?.product_type} label="Product Type" />
      <LabelText
        text={data?.account_status}
        label="Product Status"
        badgeStat
        badgeColor={
          data?.account_status_id === 1
            ? "success"
            : data?.account_status_id === 2
              ? "warning"
              : data?.account_status_id === 3
                ? "danger"
                : ""
        }
      />
      {
        data?.pum_list ?
          data?.pum_list?.length > 0 ?
            data?.pum_list?.map((pum) => {
              return (
                <>
                  <LabelText text={pum?.uom_description?.map((desc, index) => {
                    return (
                      <>
                        {
                          pum?.uom_description?.length == 1 ?
                            `${desc}.`
                            :
                            index !== pum?.uom_description?.length - 1 ?
                              `${desc}, `
                              :
                              `${desc}.`
                        }
                      </>
                    );
                  })} label={`Unit of Measure (${pum?.unit_measurement_full_name})`} />
                </>
              );
            })
            :
            <LabelText text="N/A" label="Unit of Measure" />
          :
          <LabelText text="N/A" label="Unit of Measure" />
      }
    </>
  );
};

// Product List
export const ProductTypeModalDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.product_type} label="Product Type" />
      <LabelText text={data?.products_count} label="Number of Products" />
      <LabelText
        text={data?.account_status}
        label="Product Status"
        badgeStat
        badgeColor={
          data?.account_status_id === 1
            ? "success"
            : data?.account_status_id === 2
              ? "warning"
              : data?.account_status_id === 3
                ? "danger"
                : ""
        }
      />
    </>
  );
};

// Unit Measurement
export const UnitMeasurementDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.unit_measurement_full_name} label="Unit Measurement" />
      <LabelText text={data?.unit_measurement_abbrev} label="Unit Measurement Abbreviation" />
      <LabelText
        text={data?.account_status}
        label="Product Status"
        badgeStat
        badgeColor={
          data?.account_status_id === 1
            ? "success"
            : data?.account_status_id === 2
              ? "warning"
              : data?.account_status_id === 3
                ? "danger"
                : ""
        }
      />
    </>
  );
};

// User Property
export const UserAccountPropertyDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.property_name} label="Property Name" />
      <LabelText text={data?.property_physical_address} label="Physical Address" />
      <LabelText text={data?.province_name} label="Province" />
      <LabelText text={data?.district_name} label="District" />
    </>
  );
};

// Company Representative
export const CompanyRepresentativeDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.first_name} label="First Name" />
      <LabelText text={data?.surname} label="Surname" />
      <LabelText text={data?.email_address} label="Email Address" />
      <LabelText text={data?.phone_number} label="Phone Number" />
      <LabelText text={data?.company_representative_role_name} label="Company Representative Role " />
    </>
  );
};

// Warehouse Inventory List
export const WarehouseInventoryListDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.product_name} label="Product Name" />
      <LabelText text={data?.product_type} label="Product Type" />
      <LabelText text={data?.virtual_store_capacity ? `${parseFloat(data?.virtual_store_capacity).toFixed(2)} MT` : "0.00 MT"} label="Virtual Capacity" />
      <LabelText text={data?.physical_store_capacity ? `${parseFloat(data?.physical_store_capacity).toFixed(2)} MT` : "0.00 MT"} label="Physical Capacity" />
      <LabelText text={data?.total_warehouse_store ? `${parseFloat(data?.total_warehouse_store).toFixed(2)} MT` : "0.00 MT"} label="Total Capacity " />
      <LabelText text={data?.account_status} label="Product Status" badgeStat
        badgeColor={
          data?.account_status_id === 1
            ? "success"
            : data?.account_status_id === 2
              ? "warning"
              : data?.account_status_id === 3
                ? "danger"
                : ""
        }
      />
    </>
  );
};

// Warehouse Inventory Branch List
export const WarehouseInventoryBranchDetails = ({ data }) => {
  return (
    <>
      <LabelText text={data?.branch_area} label="Branch Area" />
      <LabelText text={data?.branch_physical_address} label="Branch Physical Address" />
      <LabelText text={data?.storage_capacity ? `${parseFloat(data?.storage_capacity).toFixed(2)} MT` : "0.00 MT"} label="Physical Storage Capacity of Commodity" />
      <LabelText text={data?.branch_mobile_number} label="Branch Mobile Number" />
      <LabelText text={data?.province_name} label="Province Name" />
      <LabelText
        text={moment(data?.branch_registered_date).format("MMMM Do, YYYY h:mm:ss")}
        label="Branch Registered Date"
      />
      <LabelText
        text={data?.account_status}
        label="Account Status"
        badgeStat
        badgeColor={
          data?.account_status_id === 1
            ? "success"
            : data?.account_status_id === 2
              ? "warning"
              : data?.account_status_id === 3
                ? "danger"
                : ""
        }
      />
    </>
  );
};

// Warehouse Inventory Branch List
export const WarehouseInventoryHistoryDetails = ({ data }) => {

  return (
    <>
      <LabelText text={data?.branch_area} label="Branch Area" />
      <LabelText text={data?.branch_physical_address} label="Branch Physical Address" />
      <LabelText text={data?.storage_capacity ? `${parseFloat(data?.storage_capacity).toFixed(2)} MT` : "0.00 MT"} label="Physical Storage Capacity of Commodity" />
      <LabelText text={data?.branch_mobile_number} label="Branch Mobile Number" />
      <LabelText text={data?.province_name} label="Province Name" />
      <LabelText
        text={moment(data?.branch_registered_date).format("MMMM Do, YYYY h:mm:ss")}
        label="Branch Registered Date"
      />
      <LabelText
        text={data?.account_status}
        label="Account Status"
        badgeStat
        badgeColor={
          data?.account_status_id === 1
            ? "success"
            : data?.account_status_id === 2
              ? "warning"
              : data?.account_status_id === 3
                ? "danger"
                : ""
        }
      />
    </>
  );
};

// Warehouse Inventory Product History List
export const WarehouseInventoryProductHistoryDetails = ({ data }) => {

  return (
    <>
      <LabelText text={data?.warehouse_storage_ref} label="Warehouse Storage Ref" />
      <LabelText text={data?.warehouse_storage_type} label="Warehouse Storage Type" />
      <LabelText text={data?.capacity ? `${parseFloat(data?.capacity).toFixed(2)} MT` : "0.00 MT"} label="Capacity" />
      <LabelText
        text={moment(data?.timestamp).format("MMMM Do, YYYY h:mm:ss")}
        label="Time-Stamp"
      />
      <LabelText text={data?.message} label="Message" />
    </>
  );
};

// Department and Roles List
export const EmpDepartmentRolesDetails = ({ data }) => {

  return (
    <>
      <LabelText text={data?.warehouse_storage_ref} label="Department" />
      <LabelText text={data?.warehouse_storage_type} label="Role" />
      <LabelText text={data?.warehouse_storage_type} label="Number of Employees" />
      <LabelText text={data?.warehouse_storage_type} label="Number of Actions" />
    </>
  );
};

// GRN History List
export const GRNHistoryDetails = ({ data }) => {

  return (
    <>
      <LabelText text={data?.message} label="Message" />
      <LabelText text={data?.timestamp ?
        moment(data?.timestamp).format("MMMM Do, YYYY h:mm:ss") :
        ""} label="Timestamp" />
      <LabelText text={`${data?.issued_by_name} (${data?.issued_by_acc_id})`} label="Actor" />
    </>
  );
};