import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import { Block, BlockHead, BlockTitle, Button, Col, Icon } from '../../../components/Component';
import PageHeading from '../../dashboard_pages/components/page_heading/PageHeading';
import { Alert, Label, Spinner } from 'reactstrap';
import CardTabs from '../../dashboard_pages/components/cards/CardTabs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { CONFIRM_DELIVERY_NOTE_DELIVERED_API, RETRIEVE_SPECIFIC_CLIENT_DELIVERY_NOTE_API, SUBMIT_CUSTOMER_FEEDBACK_API } from '../../../api/api_data';
import { fail_retrieve_specific_delivery_note, loading_retrieve_specific_delivery_note, reset_retrieve_specific_delivery_note, success_retrieve_specific_delivery_note } from '../../../reducers/client_reducers/delivery_notes/retrieve_specific_delivery_note_Slice';
import { CONFIRM_DELIVERY_NOTE_DELIVERED_ACCESS_DENIED_MSG, CONFIRM_DELIVERY_NOTE_DELIVERED_ERR_MSG, CONFIRM_DELIVERY_NOTE_DELIVERED_SUCCESS_MSG, RETRIEVE_SPECIFIC_DELIVERY_NOTE_ACCESS_DENIED_MSG, RETRIEVE_SPECIFIC_DELIVERY_NOTE_ERR_MSG, RETRIEVE_SPECIFIC_DELIVERY_NOTE_SUCCESS_MSG, SUBMIT_DELIVERY_NOTE_FEEDBACK_ACCESS_DENIED_MSG, SUBMIT_DELIVERY_NOTE_FEEDBACK_ERR_MSG, SUBMIT_DELIVERY_NOTE_FEEDBACK_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { client_actions_helper } from '../../../actions/actions_helper';
import { messageToast } from '../../../utils/Utils';
import * as app_consts from "../../../constants/react_app_consts";
import { useSelector } from 'react-redux';
import ProfileDetailText from '../../dashboard_pages/components/text/ProfileDetailText';
import moment from 'moment';
import ReadOnlyTable from '../../dashboard_pages/components/table/ReadOnlyTable';
import { fail_confirm_delivery_note_delivered, loading_confirm_delivery_note_delivered, reset_confirm_delivery_note_delivered, success_confirm_delivery_note_delivered } from '../../../reducers/client_reducers/delivery_notes/confirm_delivery_note_delivered_Slice';
import CheckListTable from '../../dashboard_pages/components/table/CheckListTable';
import { fail_submit_feedback, loading_submit_feedback, reset_submit_feedback, success_submit_feedback } from '../../../reducers/client_reducers/delivery_notes/submit_feedback_Slice';

const product_list_type = app_consts.DELIVERY_NOTE_PRODUCT_LIST;
const checkListTableType = app_consts.CUSTOMER_FEEDBACK_TABLE;

const initialFeedbackState = { product_list_feedback: [], comment_feedback: "", delivery_note_ref_number: "" };

const SpecificDeliveryNote = ({ account_type }) => {

    const dispatch = useDispatch();

    const { delivery_note_ref_number } = useParams();
    const [activeCardTab, setActiveCardTab] = useState("Summary");
    const [dataFetched, setDataFetched] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [page_data, setPageData] = useState([]);
    const [delivery_note_product_list, setDeliveryNoteProductlist] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmError, setConfirmError] = useState("");
    const [delivery_feedBack, setDeliveryFeedback] = useState(initialFeedbackState);
    const [product_list_feedback, setProductListFeedback] = useState([]);
    const [submitLoading, setSubmistLoading] = useState(false);
    const [submitError, setSubmitError] = useState("");

    // useEffect that runs first when Page Opens
    useEffect(() => {
        setDeliveryFeedback({ ...delivery_feedBack, ["delivery_note_ref_number"]: delivery_note_ref_number });
        setError("");
        dispatch(
            client_actions_helper(
                loading_retrieve_specific_delivery_note,
                success_retrieve_specific_delivery_note,
                fail_retrieve_specific_delivery_note,
                RETRIEVE_SPECIFIC_DELIVERY_NOTE_ERR_MSG,
                RETRIEVE_SPECIFIC_DELIVERY_NOTE_SUCCESS_MSG,
                app_consts.POST,
                RETRIEVE_SPECIFIC_CLIENT_DELIVERY_NOTE_API,
                { delivery_note_ref_number: delivery_note_ref_number },
                RETRIEVE_SPECIFIC_DELIVERY_NOTE_ACCESS_DENIED_MSG
            )
        );
    }, []);

    // Listen to Reducer
    const delivery_note_details = useSelector((state) => state.retrieve_specific_delivery_note.retrieve_specific_delivery_note);
    // 
    useEffect(() => {
        if (delivery_note_details.status !== app_consts.IDLE)
        {
            if (delivery_note_details.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (delivery_note_details.status === app_consts.FAIL)
                {

                    setError(delivery_note_details.message);
                    messageToast(delivery_note_details.message, app_consts.FAIL);
                } else if (delivery_note_details.status === app_consts.SUCCESS)
                {
                    setPageData(delivery_note_details.data);
                    setDataFetched(true);
                    setDeliveryNoteProductlist(delivery_note_details.data?.product_list);
                }
                dispatch(reset_retrieve_specific_delivery_note());
            }
        }
    }, [delivery_note_details]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Summary", icon: "user-circle"
        },
        {
            tabName: "Weigh Bridge Information", icon: "user-circle"
        },
        {
            tabName: "Delivery Note Products", icon: "user-circle"
        },
        {
            tabName: "Delivery Note PDF", icon: "user-circle"
        },
        {
            tabName: "Customer Feedback", icon: "user-circle"
        },
    ];

    // Confirm Delivery Note Delivered
    const confirm_delivered_note_delivered = () => {
        setConfirmError("");
        dispatch(
            client_actions_helper(
                loading_confirm_delivery_note_delivered,
                success_confirm_delivery_note_delivered,
                fail_confirm_delivery_note_delivered,
                CONFIRM_DELIVERY_NOTE_DELIVERED_ERR_MSG,
                CONFIRM_DELIVERY_NOTE_DELIVERED_SUCCESS_MSG,
                app_consts.POST,
                CONFIRM_DELIVERY_NOTE_DELIVERED_API,
                { delivery_note_ref_number: delivery_note_ref_number },
                CONFIRM_DELIVERY_NOTE_DELIVERED_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to Reducer
    const confirm_delivery_note = useSelector((state) => state.confirm_delivery_note_delivered.confirm_delivery_note_delivered);
    // 
    useEffect(() => {
        if (confirm_delivery_note.status !== app_consts.IDLE)
        {
            if (confirm_delivery_note.status === app_consts.LOADING)
            {
                setConfirmLoading(true);
            } else
            {
                setConfirmLoading(false);
                if (confirm_delivery_note.status === app_consts.FAIL)
                {

                    setConfirmError(confirm_delivery_note.message);
                    messageToast(confirm_delivery_note.message, app_consts.FAIL);
                } else if (confirm_delivery_note.status === app_consts.SUCCESS)
                {
                    messageToast(CONFIRM_DELIVERY_NOTE_DELIVERED_SUCCESS_MSG, app_consts.SUCCESS);
                    setPageData(confirm_delivery_note.data);
                    setDataFetched(true);
                    setDeliveryNoteProductlist(confirm_delivery_note.data?.product_list);
                }
                dispatch(reset_confirm_delivery_note_delivered());
            }
        }
    }, [confirm_delivery_note]);

    const selectFeedbackOption = (delivery_note_product_id, selected_value) => {
        setProductListFeedback({ ...product_list_feedback, [`delivery_note_product_id`]: delivery_note_product_id, ["delivery_note_feedBack_value"]: selected_value });
    };

    useEffect(() => {
        if ((product_list_feedback && product_list_feedback.length > 0) || (product_list_feedback.length !== delivery_note_product_list?.length))
        {
            setDeliveryFeedback({ ...delivery_feedBack, ["product_list_feedback"]: [product_list_feedback] });
        }
    }, [product_list_feedback]);

    // Handle Submit
    const handleSubmit = () => {
        setSubmitError("");
        if (delivery_feedBack.product_list_feedback?.length === 0)
        {
            messageToast("Please Give Feedback for All Products Delivered on the List.", app_consts.FAIL);
        }
        else
        {
            dispatch(
                client_actions_helper(
                    loading_submit_feedback,
                    success_submit_feedback,
                    fail_submit_feedback,
                    SUBMIT_DELIVERY_NOTE_FEEDBACK_ERR_MSG,
                    SUBMIT_DELIVERY_NOTE_FEEDBACK_SUCCESS_MSG,
                    app_consts.POST,
                    SUBMIT_CUSTOMER_FEEDBACK_API,
                    delivery_feedBack,
                    SUBMIT_DELIVERY_NOTE_FEEDBACK_ACCESS_DENIED_MSG
                )
            );
        }
    };

    // Listen to Reducer
    const submit_feedback = useSelector((state) => state.submit_feedback.submit_feedback);
    // 
    useEffect(() => {
        if (submit_feedback.status !== app_consts.IDLE)
        {
            if (submit_feedback.status === app_consts.LOADING)
            {
                setSubmistLoading(true);
            } else
            {
                setSubmistLoading(false);
                if (submit_feedback.status === app_consts.FAIL)
                {

                    setSubmitError(submit_feedback.message);
                    messageToast(submit_feedback.message, app_consts.FAIL);
                } else if (submit_feedback.status === app_consts.SUCCESS)
                {
                    messageToast(SUBMIT_DELIVERY_NOTE_FEEDBACK_SUCCESS_MSG, app_consts.SUCCESS);
                    setPageData(submit_feedback.data);
                    setDataFetched(true);
                    setDeliveryNoteProductlist(submit_feedback.data?.product_list);
                }
                dispatch(reset_submit_feedback());
            }
        }
    }, [submit_feedback]);

    return (
        <>
            <Head title={`Delivery Note Details- ${delivery_note_ref_number}`} />
            <Content>
                <Block className="items-center w-80 m-auto">
                    {/* Table Page Heading */}
                    <PageHeading
                        title="Delivery Note Details"
                        title_addition={delivery_note_ref_number}
                        description="Details of Delivery Note"
                        backToText="Delivery Notes"
                        backToLink={`/customer/delivery_notes`}
                    />
                    {
                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                        {error}
                                    </div>
                                </>
                                :
                                <>
                                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                                    <div className="card-inner">
                                        {
                                            activeCardTab === "Summary" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Delivery Note Summary</BlockTitle>
                                                            <p>A Brief Summary of Delivery Note.</p>
                                                        </BlockHead>
                                                        <BlockHead>
                                                            <BlockTitle tag="h6">Brief Summary</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Delivery Note Reference Number" text={page_data?.delivery_note?.delivery_note_ref_number || "---"} />
                                                            <ProfileDetailText label="Status" text={page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status || "---"} textClassName={page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE || page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_DELIVERED ? "text-success" : "text-warning"} />
                                                            <ProfileDetailText label="Date Created" text={page_data?.delivery_note?.created_date ? moment(page_data?.delivery_note?.created_date).format("MMMM Do, YYYY h:mm:ss") : "---"} />
                                                            <ProfileDetailText label="Property Ref No." text={page_data?.user_property?.property_ref_no || "---"} />
                                                            <ProfileDetailText label="Property Name/Area" text={page_data?.user_property?.property_area || "---"} />
                                                            <ProfileDetailText label="Property Physical Address" text={page_data?.user_property?.property_physical_address || "---"} />
                                                            <ProfileDetailText label="Transit Date" text={page_data?.delivery_note?.transit_date_time ? moment(page_data?.delivery_note?.transit_date_time).format("MMMM Do, YYYY h:mm:ss") : "---"} />
                                                            <ProfileDetailText label="Delivery Date" text={page_data?.delivery_note?.delivery_date_time ? moment(page_data?.delivery_note?.delivery_date_time).format("MMMM Do, YYYY h:mm:ss") : "---"} />
                                                        </div>
                                                        <BlockHead className='mt-5'>
                                                            <BlockTitle tag="h6">Consigner Details</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="User ID" text={page_data?.user_account?.unique_identification_number || "---"} />
                                                            <ProfileDetailText label="Name" text={page_data?.consigner?.name || "---"} />
                                                            <ProfileDetailText label="Email Address" text={page_data?.consigner?.email || "---"} />
                                                            <ProfileDetailText label="Mobile number" text={page_data?.consigner?.mobile_number || "---"} />
                                                        </div>
                                                        <BlockHead className='mt-5'>
                                                            <BlockTitle tag="h6">Customer Receiving Details</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Name" text={page_data?.delivery_note?.customer_receiving_name || "---"} />
                                                            <ProfileDetailText label="Primary Mobile Number" text={page_data?.delivery_note?.customer_receiving_primary_mobile || "---"} />
                                                            <ProfileDetailText label="Secondary Mobile Number" text={page_data?.delivery_note?.customer_receiving_secondary_mobile || "---"} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                        {
                                            activeCardTab === "Weigh Bridge Information" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Delivery Information</BlockTitle>
                                                            <p>Weigh Bridge Details</p>
                                                        </BlockHead>
                                                        <BlockHead>
                                                            <BlockTitle tag="h6">Weigh Bridge Details</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Weigh Bridge Ticket Number" text={page_data?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no || "---"} />
                                                            <ProfileDetailText label="Gross Weight" text={page_data?.weigh_bridge_ticket?.first_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.first_weight).toFixed(2)} MT` : "---"} />
                                                            <ProfileDetailText label="Tare Weight" text={page_data?.weigh_bridge_ticket?.second_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.second_weight).toFixed(2)} MT` : "---"} />
                                                            <ProfileDetailText label="Net Weight" text={page_data?.weigh_bridge_ticket?.net_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.net_weight).toFixed(2)} MT` : "---"} />
                                                        </div>
                                                        <BlockHead className='mt-5'>
                                                            <BlockTitle tag="h6">Consigner Details</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="User ID" text={page_data?.user_account?.unique_identification_number || "---"} />
                                                            <ProfileDetailText label="Name" text={page_data?.consigner?.name || "---"} />
                                                            <ProfileDetailText label="Email Address" text={page_data?.consigner?.email || "---"} />
                                                            <ProfileDetailText label="Mobile number" text={page_data?.consigner?.mobile_number || "---"} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                        {
                                            activeCardTab === "Delivery Note Products" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Delivery Note Products</BlockTitle>
                                                            <p>Delivery Note product List</p>
                                                        </BlockHead>
                                                        {/* Table */}
                                                        <div className="my-3">
                                                            <ReadOnlyTable data={delivery_note_product_list} tableType={product_list_type} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                        {
                                            activeCardTab === "Customer Feedback" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Customer Feedback</BlockTitle>
                                                            <p>Customer Feedback on Delivery Notes Goods</p>
                                                        </BlockHead>
                                                        {/* Check if Status is Delivered */}
                                                        {
                                                            page_data?.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_DELIVERED ?
                                                                <>
                                                                    <div className='d-flex flex-row justify-between'>
                                                                        <div className="my-3 w-55">
                                                                            <CheckListTable amendFunction={selectFeedbackOption} data={delivery_note_product_list} tableType={checkListTableType} editMode={!page_data?.delivery_note?.customer_feedback_set} />
                                                                        </div>
                                                                        {
                                                                            page_data?.delivery_note?.customer_feedback_set ?
                                                                                <>
                                                                                    <div className="profile-ud-item">
                                                                                        <div className="d-flex flex-column">
                                                                                            <div className="d-flex flex-column">
                                                                                                <span className="profile-ud-label">Comments</span>
                                                                                                <span className={`profile-ud-value`}>{page_data?.delivery_note?.customer_feedback_comments || "---"}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div className=" w-40 h-auto">
                                                                                        <div className="form-group">
                                                                                            <Label htmlFor="default-textarea" className="form-label">
                                                                                                Comments (Optional)
                                                                                            </Label>
                                                                                            <div className="form-control-wrap">
                                                                                                <textarea
                                                                                                    className="no-resize form-control "
                                                                                                    type="textarea"
                                                                                                    id="default-textarea"
                                                                                                    value={delivery_feedBack.comment_feedback}
                                                                                                    onChange={(e) => setDeliveryFeedback({ ...delivery_feedBack, ["comment_feedback"]: e.target.value })}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                    {submitError && (
                                                                        <div className="mb-3 w-50 m-auto">
                                                                            <Alert color="danger" className="alert-icon">
                                                                                <Icon name="alert-circle" /> {submitError}{" "}
                                                                            </Alert>
                                                                        </div>
                                                                    )}
                                                                    {
                                                                        !page_data?.delivery_note?.customer_feedback_set && (
                                                                            <>
                                                                                <Col xl="12">
                                                                                    <div className="form-group">
                                                                                        <div className="mt-4 form-control-wrap-flex-wrap justify-center items">
                                                                                            <Button
                                                                                                onClick={handleSubmit}
                                                                                                disabled={submitLoading || delivery_feedBack.product_list_feedback === 0}
                                                                                                color="primary"
                                                                                                size="lg"
                                                                                            >
                                                                                                {submitLoading ? <Spinner size="sm" color="light" /> : "Submit FeedBack"}
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                                                        Customer Feedback Can Only Be Submitted After a Customer Confirms Arrival of Delivery that is in Transit.
                                                                    </div>
                                                                    <div className='text-soft alert-icon w-full d-flex justify-center items-center fs-16px'>
                                                                        <div className='p-1'>Current Status: </div><div className='fw-bold primary p-1 upprcase'>{page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status}</div>
                                                                    </div>
                                                                    {confirmError && (
                                                                        <div className="mb-3 w-50 m-auto">
                                                                            <Alert color="danger" className="alert-icon">
                                                                                <Icon name="alert-circle" /> {confirmError}{" "}
                                                                            </Alert>
                                                                        </div>
                                                                    )}
                                                                    <div className='text-soft alert-icon w-full p-2 d-flex justify-center items-center fw-bold fs-18px'>
                                                                        <Button onClick={confirm_delivered_note_delivered} color="primary" size="lg" disabled={confirmLoading ? confirmLoading : page_data?.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id !== app_consts.WEIGH_BRIDGE_TICKET_IN_TRANSIT}>
                                                                            {confirmLoading ? <Spinner size="sm" color="light" /> : "Confirm Delivery"}
                                                                        </Button>
                                                                    </div>

                                                                </>
                                                        }
                                                    </Block>
                                                </>
                                            )
                                        }
                                    </div>
                                </>
                    }
                </Block>
            </Content>
        </>
    );
};

export default SpecificDeliveryNote;