import React, { useEffect, useState } from 'react';
import { Button, Col } from '../../../../components/Component';
import { Card, CardBody, CardFooter, CardHeader, CardText, CardTitle } from 'reactstrap';
import Toggle from 'react-toggle';
import "react-toggle/style.css"; // for ES6 modules

const ToggleCard = ({ title, isLoading, isToggled, sm, lg, xxl }) => {

 const [loading, setLoading] = useState(isLoading);
 const [isChecked, setIsChecked] = useState(isToggled);

 useEffect(() => {
  setLoading(isLoading);
  setIsChecked(isToggled);
 }, [isLoading]);

 return (
  <>
   <Col sm={sm} lg={lg} xxl={xxl} >
    <div className='fs-16px p-4 bg-lighter border-gray rounded d-flex align center justify-between'>
     <p className=''>{title}</p>
     <Toggle
      // defaultChecked={this.state.tofuIsReady}
      className='light h-6 w-6'
      icons={false}
     // onChange={this.handleTofuChange}
     />
    </div>
   </Col>
  </>
 );
};

export default ToggleCard;