import React from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockBetween, BlockHead, BlockHeadContent } from "../../../components/Component";
import UAGLogo from "../../../images/logo.png";

const LoadingApp = () => {
  return (
    <>
      <Head title="Setting Up Environment" />
      <Content page="component">
        <Block size="lg">
          <BlockBetween className="center">
            <BlockHead>
              <BlockHeadContent>
                <div className="brand-logo mt-3 text-center">
                  <img className="logo-img logo-img-app_loading" src={UAGLogo} alt="logo-dark" />
                </div>
                <div className="text-soft fs-18px">Setting up Environment ...</div>
                <div className="nk-loader"></div>
              </BlockHeadContent>
            </BlockHead>
          </BlockBetween>
        </Block>
      </Content>
    </>
  );
};

export default LoadingApp;
