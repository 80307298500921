// Feedback Messages
export const SUCCESS = "SUCCESS";
export const LOADING = "LOADING";
export const FAIL = "FAIL";
export const IDLE = "IDLE";
export const PAGE_ACCESS_DENIED = "PAGE_ACCESS_DENIED";

// Other
export const SEND_OTP_MOBILE = "SEND_OTP_MOBILE";
export const SEND_OTP_EMAIL = "SEND_OTP_EMAIL";
export const PAGE_INACTIVE = "PAGE_INACTIVE";
export const PAGE_UNDER_MAINTENACNE = "PAGE_UNDER_MAINTENACNE";
export const INDEX = "INDEX";
export const USER_LIST_TYPE = "USER_LIST_TYPE";
export const ACTIVATE_ACCOUNT_ID = 1;
export const SUSPEND_ACCOUNT_ID = 2;
export const DEACTIVATE_ACCOUNT_ID = 3;
export const ACTIVATE_ACCOUNT = "Activate";
export const SUSPEND_ACCOUNT = "Suspend";
export const DEACTIVATE_ACCOUNT = "Deactivate";
export const BUTTON_OPEN_PAGE_TYPE = "BUTTON_OPEN_PAGE_TYPE";
export const BUTTON_OPEN_MODAL_TYPE = "BUTTON_OPEN_MODAL_TYPE";
export const ASSIGN_USER_ACCOUNTS = "ASSIGN_USER_ACCOUNT";
export const COMPLETE_KYC_REGISTRATION = "COMPLETE_KYC_REGISTRATION";
export const ASSIGN_CUSTOMER_ACCOUNT = "ASSIGN_CUSTOMER_ACCOUNT";
export const RAW = "RAW";
export const MAP = "MAP";
export const BRANCH_LOCATION_LIST = "BRANCH_LOCATION_LIST";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_TYPE_LIST = "PRODUCT_TYPE_LIST";
export const UNIT_MEASUREMENT_LIST = "UNIT_MEASUREMENT_LIST";
export const ONBOARD_USER_PROPERTY = "ONBOARD_USER_PROPERTY";
export const ONBOARD_COMPANY_REPRESENTATIVE = "ONBOARD_COMPANY_REPRESENTATIVE";
export const ONBOARD_COMPANY_REPRESENTATIVE_ROLE = "ONBOARD_COMPANY_REPRESENTATIVE_ROLE";
export const WEIGH_BRIDGE_TICKET_LIST = "WEIGH_BRIDGE_TICKET_LIST";
export const COMPANY_USER_ACCOUNTS_LIST = "COMPANY_USER_ACCOUNTS_LIST";
export const CUSTOMER_USER_ACCOUNTS_LIST = "CUSTOMER_USER_ACCOUNTS_LIST";
export const TRUCK_USER_ACCOUNTS_LIST = "TRUCK_USER_ACCOUNTS_LIST";
export const EMPLOYEE_LIST_TYPE = "EMPLOYEE_LIST_TYPE";
export const GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST = "GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST";
export const SPECIFIC_BRANCH_LOCATION_STORAGES = "SPECIFIC_BRANCH_LOCATION_STORAGES";
export const ADD_WAREHOUSE_STORAGE = "ADD_WAREHOUSE_STORAGE";
export const ADD_WAREHOUSE_STORAGE_TYPE = "ADD_WAREHOUSE_STORAGE_TYPE";
export const WAREHOUSE_INVENTORY_LIST = "WAREHOUSE_INVENTORY_LIST";
export const SPECIFIC_BRANCH_EMPLOYEES = "SPECIFIC_BRANCH_EMPLOYEES";
export const DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST = "DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST";
export const CREATE_PHYSICAL_DELIVERY_NOTE_TICKET = "CREATE_PHYSICAL_DELIVERY_NOTE_TICKET";
export const CREATE_VIRTUAL_DELIVERY_NOTE_TICKET = "CREATE_VIRTUAL_DELIVERY_NOTE_TICKET";
export const ADD_PRODUCTS_TO_DELIVERY_NOTE_TICKET = "ADD_PRODUCTS_TO_DELIVERY_NOTE_TICKET";
export const USER_ACCOUNT_LIST = "USER_ACCOUNT_LIST";
export const CLIENT_GOODS_RECEIVED_LIST = "CLIENT_GOODS_RECEIVED_LIST";
export const CLIENT_DELIVERY_NOTES_LIST = "CLIENT_DELIVERY_NOTES_LIST";
export const CUSTOMER_USER_ACCOUNT_LIST = "CUSTOMER_USER_ACCOUNT_LIST";
export const DELIVERY_NOTE_PRODUCT_LIST = "DELIVERY_NOTE_PRODUCT_LIST";
export const COMPANY_USER_ACCOUNT_LIST = "COMPANY_USER_ACCOUNT_LIST";
export const TRUCK_DRIVER_USER_ACCOUNT_LIST = "TRUCK_DRIVER_USER_ACCOUNT_LIST";
export const WAREHOUSE_INVENTORY_BRANCHES_LIST = "WAREHOUSE_INVENTORY_BRANCHES_LIST";
export const INVENTORY_HISTORY_LIST = "INVENTORY_HISTORY_LIST";
export const DELIVERY_NOTE_DETAILS = "DELIVERY_NOTE_DETAILS";
export const CUSTOMER_FEEDBACK_TABLE = "CUSTOMER_FEEDBACK_TABLE";
export const CREATE_PHYSICAL_GOODS_RECEIVED_TICKET = "CREATE_PHYSICAL_GOODS_RECEIVED_TICKET";
export const CREATE_VIRTUAL_GOODS_RECEIVED_TICKET = "CREATE_VIRTUAL_GOODS_RECEIVED_TICKET";
export const SPECIFIC_GOODS_RECEIVED_DETAILS = "SPECIFIC_GOODS_RECEIVED_DETAILS";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const DEPARTMENT_AND_ROLES_LIST = "DEPARTMENT_AND_ROLES_LIST";
export const ROLE_EMPLOYEES_LIST = "ROLE_EMPLOYEES_LIST";
export const BRANCH_LOCATION_MAP = "BRANCH_LOCATION_MAP";
export const ADD_PRODUCTS_TO_GOODS_RECEIVED_NOTE_TICKET = "ADD_PRODUCTS_TO_GOODS_RECEIVED_NOTE_TICKET";
export const GOODS_RECEIVED_PRODUCTS_LIST = "GOODS_RECEIVED_PRODUCTS_LIST";
export const VIEW_GOODS_RECEIVED_PRODUCTS_LIST = "VIEW_GOODS_RECEIVED_PRODUCTS_LIST";
export const LOADING_ORDERS_LIST = "LOADING_ORDERS_LIST";
export const DRIVER_INSTRUCTIONS_TABLE_EDIT = "DRIVER_INSTRUCTIONS_TABLE_EDIT";
export const ADD_LOADING_ORDER_PRODUCT_TABLE = "ADD_LOADING_ORDER_PRODUCT_TABLE";
export const USER_ACCOUNT_PROPERTY_MAP = "USER_ACCOUNT_PROPERTY_MAP";
export const DRIVER_INSTRUCTIONS_TABLE = "DRIVER_INSTRUCTIONS_TABLE";
export const DELIVERY_LOCATION_MAP = "DELIVERY_LOCATION_MAP";
export const NOT_LOADED_DN_EDIT_MAP = "NOT_LOADED_DN_EDIT_MAP";
export const CREATE_PHYSICAL_LOADING_ORDER = "CREATE_PHYSICAL_LOADING_ORDER";
export const GRN_HISTORY = "GRN_HISTORY";
export const USER_DETAILS = 'USER_DETAILS';
export const ADD_USER_ACCOUNT_MODAL = "ADD_USER_ACCOUNT_MODAL";
export const PRODUCT_WAREHOUSE_INVENTORY_PAGE = "PRODUCT_WAREHOUSE_INVENTORY_PAGE";
export const ADJUST_INVENTORY_MODAL = "ADJUST_INVENTORY_MODAL";


// Ticket Types
export const VIRTUAL_TICKET_TYPE = 1;
export const PHYSICAL_TICKET_TYPE = 2;


// Wizard Types

// Consigner Types
export const UAG_CONSIGNER_TYPE = 1;
export const COMPANY_CONSIGNER_TYPE = 2;
export const CUSTOMER_CONSIGNER_TYPE = 3;

// Refresh Lists
export const REFRESH_USER_ACCOUNTS = "REFRESH_USER_ACCOUNTS";

// Default Country Code
export const DEFAULT_ISO_CODE_2 = "ZM";

// Image Type
export const IMAGE_SINGLE = "IMAGE_SINGLE";

// Preview Type
export const PREVIEW_ONBOARD_TRUCK = "PREVIEW_ONBOARD_TRUCK";
export const PREVIEW_ONBOARD_TRAILER = "PREVIEW_ONBOARD_TRAILER";
export const PREVIEW_ADD_BRANCH_LOCATION = "PREVIEW_ADD_BRANCH_LOCATION";
export const PREVIEW_CREATE_PHYSICAL_WEIGHBRIDGE_TICKET = "PREVIEW_CREATE_PHYSICAL_WEIGHBRIDGE_TICKET";
export const BOOK_PHYSICAL_GOODS_RECEIVED_INTO_STORAGE = "BOOK_PHYSICAL_GOODS_RECEIVED_INTO_STORAGE";
export const PREVIEW_CREATE_PHYSICAL_DELIVERY_NOTE_WEIGHBRIDGE_TICKET = "PREVIEW_CREATE_PHYSICAL_DELIVERY_NOTE_WEIGHBRIDGE_TICKET";
export const FINAL_DELIVERY_NOTE_DETAILS = "FINAL_DELIVERY_NOTE_DETAILS";

// API Request Methods
export const POST = "post";
export const GET = "get";

// Rselct Map Types
export const USER_MAP = "USER_MAP";
export const GENDER_MAP = "GENDER_MAP";
export const MARITAL_STATUS_MAP = "MARITAL_STATUS_MAP";
export const USER_TITLE_MAP = "USER_TITLE_MAP";
export const PROVINCES_MAP = "PROVINCES_MAP";
export const PRODUCT_TYPE_MAP = "PRODUCT_TYPE_MAP";
export const AUTH_ROUTES_MAP = "AUTH_ROUTES_MAP";
export const COMPANY_REPRESENTATIVE_ROLES_MAP = "COMPANY_REPRESENTATIVE_ROLES_MAP";
export const WEIGH_BRIDGE_TICKET_TYPE_MAP = "WEIGH_BRIDGE_TICKET_TYPE_MAP";
export const WAREHOUSE_STORAGE_TYPES_MAP = "WAREHOUSE_STORAGE_TYPES_MAP";
export const PRODUCTS_MAP = "PRODUCTS_MAP";
export const UNIT_MEASUREMENTS_MAP = "UNIT_MEASUREMENTS_MAP";
export const BRANCH_STORAGES_TYPES_MAP = "BRANCH_STORAGES_TYPES_MAP";
export const UOM_MAP = "UOM_MAP";
export const PROVINCES_DISTRICTS_COUNTRY_MAP = "PROVINCES_DISTRICTS_COUNTRY_MAP";

// Split Variable
export const FULLNAME_SPLIT = "FULLNAME_SPLIT";
export const PHONE_NUMBER_SPLIT = "PHONE_NUMBER_SPLIT";
export const FIRST_NAME_SPLIT = "FIRST_NAME_SPLIT";
export const SURNAME_SPLIT = "SURNAME_SPLIT";

// Statuses
export const WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE = 2;
export const WEIGH_BRIDGE_TICKET_ONBOARDING_GOODS = 5;
export const WEIGH_BRIDGE_TICKET_PROCESSING_OUTBOUND = 7;
export const WEIGH_BRIDGE_TICKET_IN_TRANSIT = 8;
export const WEIGH_BRIDGE_TICKET_DELIVERED = 9;

// Account Types
export const DASHBOARD_USER_ACCOUNT_TYPE = 1;
export const CUSTOMER_ACCOUNT_TYPE = 2;
export const TRUCK_DRIVER_ACCOUNT_TYPE = 3;
export const COMPANY_REPRESENTATIVE_ACCOUNT_TYPE = 4;
export const COMPANY_ACCOUNT_TYPE = 5;
export const DASHBOARD_USER_ACCOUNT_TYPE_STRING = "dashboard user";

// Component Params Actions
export const COMP_AMEND_DELIVERY_NOTE_STATUS_ACTIONS = "COMP_AMEND_DELIVERY_NOTE_STATUS_ACTIONS";
export const CONTINUE_PROCESSING_GOODS_RECEIVED_ACTIONS = "CONTINUE_PROCESSING_GOODS_RECEIVED_ACTIONS";
export const CONTINUE_PROCESSING_DELIVERY_NOTE_ACTIONS = "CONTINUE_PROCESSING_DELIVERY_NOTE_ACTIONS";
export const CONTINUE_PROCESSING_LOADING_ORDER_ACTIONS = "CONTINUE_PROCESSING_LOADING_ORDER_ACTIONS";

// Other 
export const GREATER_THAN = "gt";
export const EQUAL_TO = "et";
export const LESS_THAN = "lt";
export const DELETE = "DELETE";
export const EDIT = "Edit";
export const LOAD = "Load";
export const LOADED = "loaded";
export const NOT_LOADED = "not loaded";

// Module Constants
export const SYSTEM_MANAGEMENT = "system_management";
export const ONBOARDING = "onboarding";
export const USER_MANAGEMENT = "user_management";
export const WAREHOUSE_MANAGEMENT = "warehouse_management";

// Module Actions  ""
export const VIEW_BRANCH_LOCATIONS = "view_branch_locations";
export const VIEW_PRODUCT = "view_product";
export const VIEW_PRODUCT_TYPE = "view_product_type";
export const VIEW_UNIT_MEASUREMENT = "view_unit_measurement";
export const ONBOARD_USER = "onboard_user";
export const ONBOARD_COMPANY = "onboard_company";
export const VIEW_WAREHOUSE_INVENTORY = "view_warehouse_inventory";
export const VIEW_USERS = "view_users";
export const VIEW_USER_ACCOUNTS = "view_user_accounts";
export const CREATE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET = "create_goods_received_weigh_bridge_ticket";
export const VIEW_DEPARTMENTS_AND_ROLES = "view_departments_and_roles";
export const VIEW_LOADING_ORDERS = "view_loading_orders";
export const CREATE_LOADING_ORDER = "create_loading_order";
export const AMEND_USER_STATUS = "amend_user_status";
export const ADD_PRODUCT = "add_product";
export const ADD_PRODUCT_TYPE = "add_product_type";
export const ADD_BRANCH_LOCATION = "add_branch_location";
export const AMEND_BRANCH_LOCATION_STATUS = "amend_branch_location_status";
export const AMEND_PRODUCT_STATUS = "amend_product_Status";
export const AMEND_PRODUCT_PRODUCT_TYPE_STATUS = "amend_product_type_status";
export const ADD_UNIT_MEASUREMENT = "add_unit_measurement";
export const AMEND_UNIT_MEASUREMENT_STATUS = "amend_unit_measurement_status";
export const CREATEd_DELIVERY_NOTE_WEIGH_BRIDGE_TICKET = "create_delivery_note_weigh_bridge_ticket";
export const CONTINUE_PROCESSING_DELIVERY_WEIGH_BRIDGE_TICKET = "continue_processing_weigh_bridge_ticket";
export const ONBOARD_COMPANY_SEARCH = "onboard_company_search";
export const ASSIGN_SYSTEM_ADMINISTRATOR = "assign_system_administrator";
export const ASSIGN_DASHBOARD_USER = "assign_dashboard_user";
export const ASSIGN_CUSTOMER = "assign_customer";
export const ADJUST_INVENTORY = "adjust_inventory";



;

// WeighBridge Statuses
export const PENDING_DN_PROCESSING = "pending dn processing";
export const UNDERGOING_PROCESSING = "Undergoing Processing";
export const PROCESSING_COMPLETE = "Processing Complete";

// UOMs
export const PER_LITRE = "p/Lt";
export const PER_KG = "p/kg";
export const ONE_TON = "1 Ton";
export const FIFTY_KG = "50kg";
export const FIVE_HUNDRED_KG = "500kg";

// 