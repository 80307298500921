import * as app_consts from "../constants/react_app_consts";

export const retreive_rSelectVariables = (mapType, data) => {
  let label;
  let value;
  //   User Details Map
  if (mapType === app_consts.USER_MAP)
  {
    label = `${data.first_name} ${data.surname} - ${data.phone_number}`;
    value = data.phone_number;
  }
  // Gender Type Map
  else if (mapType === app_consts.GENDER_MAP)
  {
    label = data.gender_type;
    value = data.gender_type_id;
  }
  // Marital Status map
  else if (mapType === app_consts.MARITAL_STATUS_MAP)
  {
    label = data.marital_status;
    value = data.marital_status_id;
  }
  // User Title Map
  else if (mapType === app_consts.USER_TITLE_MAP)
  {
    label = data.user_title;
    value = data.user_title_id;
  }
  // Provinces Map
  else if (mapType === app_consts.PROVINCES_MAP)
  {
    label = data.province_name;
    value = data.province_id;
  }
  // Product Type Map
  else if (mapType === app_consts.PRODUCT_TYPE_MAP)
  {
    label = data.product_type;
    value = data.product_type_id;
  }
  // Company Representative Roles Map
  else if (mapType === app_consts.COMPANY_REPRESENTATIVE_ROLES_MAP)
  {
    label = data.company_representative_role_name;
    value = data.company_representative_role_id;
  }
  // Weigh Bridge Ticket Map
  else if (mapType === app_consts.WEIGH_BRIDGE_TICKET_TYPE_MAP)
  {
    label = data.weigh_bride_ticket_type;
    value = data.weigh_bridge_ticket_type_id;
  }
  // Weigh Bridge Storage Types Map
  else if (mapType === app_consts.WAREHOUSE_STORAGE_TYPES_MAP)
  {
    label = data.warehouse_storage_type;
    value = data.warehouse_storage_type_id;
  }
  // Products Map
  else if (mapType === app_consts.PRODUCTS_MAP)
  {
    label = data.product_name;
    value = data.product_id;
  }
  // Unit Measurements Map
  else if (mapType === app_consts.UNIT_MEASUREMENTS_MAP)
  {
    label = `${data.unit_measurement_full_name} (${data.unit_measurement_abbrev})`;
    value = data.unit_measurement_id;
  }
  // Branch Storages Map
  else if (mapType === app_consts.BRANCH_STORAGES_TYPES_MAP)
  {
    label = data.warehouse_storage_type;
    value = data.warehouse_storage_id;
  }
  // Branch Locations Map
  else if (mapType === app_consts.BRANCH_LOCATION_MAP)
  {
    label = `${data.branch_area} - ${data.province_name}`;
    value = data.branch_location_id;
  }
  // UOM Map
  else if (mapType === app_consts.UOM_MAP)
  {
    label = data;
    value = data;
  }
  else if (mapType === app_consts.PROVINCES_DISTRICTS_COUNTRY_MAP)
  {
    label = data;
    value = data;
  }
  else if (mapType === app_consts.USER_ACCOUNT_PROPERTY_MAP)
  {
    label = `${data.property_physical_address} ${data.district ? `- ${data.district} ,${data.province}, ${data.country}` : ""} `;
    value = data.property_id;
  }
  else if (mapType === app_consts.LOADING_ORDERS_LIST)
  {
    label = `${data.loading_order_ref_no} - ${data.owner}`;
    value = data.loading_order_ref_no;
  }
  else if (mapType === app_consts.DELIVERY_LOCATION_MAP)
  {
    label = `${data.location_name}`;
    value = data.location_id;
  }


  return { label, value };
};

export const map_system_actions = async (res_emp_api_roles) => {
  let return_actions = {};

  if (res_emp_api_roles)
  {
    // RETURN MODULE ACTION IN A MAP
    const module_actions = res_emp_api_roles.module_actions?.reduce((acc, mod_act) => {
      acc[mod_act.module_action_name] = true;
      return acc;
    }, {});

    return_actions = {
      [res_emp_api_roles.module_name]: true,
      module_actions: module_actions,
    };
  }

  return return_actions;
};

// Split Name and Phone Number From Rselect
export const name_phone_number_splitter = (inputString, type) => {
  let return_string;

  const parts = inputString.split("-");

  if (type === app_consts.FULLNAME_SPLIT)
  {
    // Extract the first two words (name)
    return_string = parts[0].trim();
  } else if (type === app_consts.PHONE_NUMBER_SPLIT)
  {
    // Extract the Phone Number
    return_string = parts[1].trim();
  }
  return return_string;
};

// Convert to Title Case
export const convertToTitleCase = (str) => {
  // Split the sentence into words
  let words = str?.split(' ');

  // Iterate over each word
  for (let i = 0; i < words?.length; i++)
  {
    // Capitalize the first letter of the word
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back into a sentence
  return words?.join(' ');
};

// Add underscores to Words
export const underscore_words = (words) => {
  // Convert the sentence to lowercase
  let lowercaseSentence = words.toLowerCase();
  // Remove symbols
  let withoutSymbols = lowercaseSentence.replace(/[^\w\s]/g, "");
  // Replace white spaces with underscore
  let formattedSentence = withoutSymbols.replace(/\s/g, "_");
  return formattedSentence;
};

// Replace Underscores with Spaces
export const capitalizeWords = (words) => {
  // Replace underscores with spaces
  let withSpaces = words.replace(/_/g, " ");
  // Capitalize every word
  let capitalizedSentence = withSpaces.replace(/\b\w/g, match => match.toUpperCase());
  return capitalizedSentence;
};

// Find Actions
export const findModuleAction = (moduleActionName, module) => {
  return module?.module_actions?.find(action => action?.module_action_name?.toLowerCase() === moduleActionName?.toLowerCase());
};

export function convertPackagingUnits (packagingNumber, packagingUnit) {
  const unitsInTonnes = {
    "kg": 0.001,
    "ton": 1,
    "p/lt": 0.001,
    "p/kg": 0.001
  };

  // Convert the unit to lowercase
  let unitType = packagingUnit?.toLowerCase();
  let value = 1;

  // If the unit contains numbers, split it into value and unit type
  if (/\d/.test(packagingUnit))
  {
    [value, unitType] = packagingUnit.toLowerCase().split(/(?<=\d)(?=\D)/);
  }

  // Default value to 1 if it's undefined or unit doesn't contain numbers
  value = value ? parseFloat(value) : 1;

  const convertedValue = value * packagingNumber;
  const metricTonnes = convertedValue * (unitsInTonnes[unitType] || 1); // Default to 1 if unit is not found

  return {
    original: convertedValue,
    metricTonnes: metricTonnes.toFixed(2)
  };
}

export const toTitleCase = (str) => {
  if (typeof str !== "string") return "";

  return str
    .split('_') // Split the string into words using underscores
    .map((word) =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize each word
    )
    .join(' '); // Join the words with a space
};