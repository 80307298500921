import React, { useEffect, useState } from "react";
import {
  Block,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  PaginationComponent,
} from "../../../../components/Component";
import { read_Only_Columns } from "./table_list_data/table_list_columns";
import * as app_consts from "../../../../constants/react_app_consts";
import { ReadOnlyCompanyRepData, ReadOnlyDeliveryNoteProductData, ReadOnlyDeliveryNoteProductsData, ReadOnlyDNProductNotLoadedData, ReadOnlyDriversInstructionsListData, ReadOnlyDriversInstructionsListEditData, ReadOnlyEmployeeData, ReadOnlyGoodsReceivedProductListData, ReadOnlyGrnHistoryData, ReadOnlyLoadingOrderProductListtData, ReadOnlyOnboardCompanyUserSearchData, ReadOnlyProperyData, ReadOnlyRoleEmployeesListData, ReadOnlyUserAccountData, ReadOnlyViewGoodsReceivedProductListData, ReadOnlyWarehouseInventoryBranchListData, ReadOnlyWarehouseInventoryProductHistoryListData, ReadOnlyWarehouseStorageData } from "./table_components/read_only_row_data";
import ModalDetails from "../modal/modal_details/ModalDetails";

const ReadOnlyTable = ({ data, tableType, setSelectedData, extra_column, page_size = 10, action_function }) => {
  const [tableData, setTableData] = useState(data);
  const [type, setType] = useState(tableType);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(page_size);
  const [tableColumns, setTableColumns] = useState(read_Only_Columns(tableType));
  const [openViewModal, setViewModal] = useState(false);
  const [detail, setDetail] = useState([]);
  const [selectedDataID, setSelectedDataID] = useState("");

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setType(tableType);
    setTableColumns(read_Only_Columns(tableType));
  }, [tableType]);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = tableData?.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // UseEffect for Specific Data
  useEffect(() => {
    if (selectedDataID || selectedDataID === 0)
    {
      setDetail(filterSpecificUser(selectedDataID));
    }
  }, [selectedDataID]);

  // Filter Specific Data
  const filterSpecificUser = (selected_data) => {
    let filtereredData;
    if (type === app_consts.ONBOARD_USER_PROPERTY)
    {
      filtereredData = tableData?.filter((data) => data.property_id === selected_data);
    } else if (type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE)
    {
      filtereredData = tableData?.filter((data) => data.member_access_id === selected_data);
    }
    // Warehouse Location Branch Inventory
    else if (type === app_consts.WAREHOUSE_INVENTORY_BRANCHES_LIST)
    {
      filtereredData = tableData?.filter((data) => data.branch_location_id === selected_data);
    }
    // Warehouse Location Branch Inventory
    else if (type === app_consts.INVENTORY_HISTORY_LIST)
    {
      const retrieved_index = tableData[selected_data];
      filtereredData = [retrieved_index];
    }
    // Add Company User Search
    else if (type === app_consts.ONBOARD_COMPANY_SEARCH)
    {
      filtereredData = tableData?.filter((data) => data.user_id === selected_data);
    }
    // GRN History
    else if (type === app_consts.GRN_HISTORY)
    {
      filtereredData = tableData?.filter((data) => data.timestamp === selected_data);
    }
    return filtereredData[0];
  };

  return (
    <>
      <Block>
        <DataTable className="card-stretch">
          <DataTableBody>
            <DataTableHead>
              {tableColumns &&
                tableColumns?.map((column) => {
                  return (
                    <DataTableRow key={column.key}>
                      <span>{column.label}</span>
                    </DataTableRow>
                  );
                })}
              {
                extra_column && (
                  <>
                    <DataTableRow className="nk-tb-col-tools">&nbsp;</DataTableRow>
                  </>
                )
              }
            </DataTableHead>
            {/* Table Rows */}
            {
              // Property Data
              type === app_consts.ONBOARD_USER_PROPERTY ? (
                <ReadOnlyProperyData
                  data={tableData}
                  currentItems={currentItems}
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                />
              ) : type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE ? (
                <ReadOnlyCompanyRepData
                  data={tableData}
                  currentItems={currentItems}
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                />
              ) : type === app_consts.SPECIFIC_BRANCH_LOCATION_STORAGES ? (
                <ReadOnlyWarehouseStorageData
                  data={tableData}
                  currentItems={currentItems}
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                />
              )
                // Ready Only Employee List
                : type === app_consts.SPECIFIC_BRANCH_EMPLOYEES ? (
                  <ReadOnlyEmployeeData
                    data={tableData}
                    currentItems={currentItems}
                    setViewModal={setViewModal}
                    setSelectedDataID={setSelectedDataID}
                  />
                )
                  // Ready Only Deivery Note Products
                  : type === app_consts.ADD_PRODUCTS_TO_DELIVERY_NOTE_TICKET ? (
                    <ReadOnlyDeliveryNoteProductData
                      data={tableData}
                      currentItems={currentItems}
                      setViewModal={setViewModal}
                      setSelectedDataID={setSelectedDataID}
                    />
                  )
                    // Ready Only User Account list
                    : type === app_consts.USER_ACCOUNT_LIST ? (
                      <ReadOnlyUserAccountData
                        data={tableData}
                        currentItems={currentItems}
                        setViewModal={setViewModal}
                        setSelectedDataID={setSelectedDataID}
                      />
                    ) :
                      // Ready Only Delivery Note Product List
                      type === app_consts.DELIVERY_NOTE_PRODUCT_LIST ? (
                        <ReadOnlyDeliveryNoteProductsData
                          data={tableData}
                          currentItems={currentItems}
                          setViewModal={setViewModal}
                          setSelectedDataID={setSelectedDataID}
                        />
                      ) :
                        // Ready Only Warehouse Inventory Branch List
                        type === app_consts.WAREHOUSE_INVENTORY_BRANCHES_LIST ? (
                          <ReadOnlyWarehouseInventoryBranchListData
                            data={tableData}
                            currentItems={currentItems}
                            setViewModal={setViewModal}
                            setSelectedDataID={setSelectedDataID}
                            action_function={action_function}
                          />
                        ) :
                          // Ready Only Warehouse Inventory Product History List
                          type === app_consts.INVENTORY_HISTORY_LIST ? (
                            <ReadOnlyWarehouseInventoryProductHistoryListData
                              data={tableData}
                              currentItems={currentItems}
                              setViewModal={setViewModal}
                              setSelectedDataID={setSelectedDataID}
                            />
                          ) :
                            // Ready Only Role Employee List
                            type === app_consts.ROLE_EMPLOYEES_LIST ? (
                              <ReadOnlyRoleEmployeesListData
                                data={tableData}
                                currentItems={currentItems}
                                setViewModal={setViewModal}
                                setSelectedDataID={setSelectedDataID}
                              />
                            ) :
                              // Ready Only Goods Recieved Product List
                              type === app_consts.GOODS_RECEIVED_PRODUCTS_LIST ? (
                                <ReadOnlyGoodsReceivedProductListData
                                  data={tableData}
                                  currentItems={currentItems}
                                  setViewModal={setViewModal}
                                  setSelectedDataID={setSelectedDataID}
                                />
                              ) :
                                // Ready Only View Goods Recieved Product List
                                type === app_consts.SPECIFIC_GOODS_RECEIVED_DETAILS ? (
                                  <ReadOnlyViewGoodsReceivedProductListData
                                    data={tableData}
                                    currentItems={currentItems}
                                    setViewModal={setViewModal}
                                    setSelectedDataID={setSelectedDataID}
                                  />
                                ) :
                                  // Ready Only Driver Instructions List Table EDIT
                                  type === app_consts.DRIVER_INSTRUCTIONS_TABLE_EDIT ? (
                                    <ReadOnlyDriversInstructionsListEditData
                                      data={tableData}
                                      currentItems={currentItems}
                                      setViewModal={setViewModal}
                                      setSelectedDataID={setSelectedDataID}
                                      setSelectedData={setSelectedData}
                                    />
                                  ) :
                                    // Ready Only Loading Order Product List
                                    type === app_consts.ADD_LOADING_ORDER_PRODUCT_TABLE ? (
                                      <ReadOnlyLoadingOrderProductListtData
                                        data={tableData}
                                        currentItems={currentItems}
                                        setViewModal={setViewModal}
                                        setSelectedDataID={setSelectedDataID}
                                        setSelectedData={setSelectedData}
                                      />
                                    ) :
                                      // Product NOT Loaded Edit Map
                                      type === app_consts.NOT_LOADED_DN_EDIT_MAP ? (
                                        <ReadOnlyDNProductNotLoadedData
                                          data={tableData}
                                          currentItems={currentItems}
                                          setViewModal={setViewModal}
                                          setSelectedDataID={setSelectedDataID}
                                          setSelectedData={setSelectedData}
                                        />
                                      ) :
                                        // Ready Only Driver Instructions List
                                        type === app_consts.DRIVER_INSTRUCTIONS_TABLE ? (
                                          <ReadOnlyDriversInstructionsListData
                                            data={tableData}
                                            currentItems={currentItems}
                                            setViewModal={setViewModal}
                                            setSelectedDataID={setSelectedDataID}
                                            setSelectedData={setSelectedData}
                                          />
                                        ) :
                                          // Ready Only Onboard Company User Search
                                          type === app_consts.ONBOARD_COMPANY_SEARCH ? (
                                            <ReadOnlyOnboardCompanyUserSearchData
                                              data={tableData}
                                              currentItems={currentItems}
                                              setViewModal={setViewModal}
                                              setSelectedDataID={setSelectedDataID}
                                              setSelectedData={setSelectedData}
                                            />
                                          ) :
                                            // Ready Only GRN History List
                                            type === app_consts.GRN_HISTORY ? (
                                              <ReadOnlyGrnHistoryData
                                                data={tableData}
                                                currentItems={currentItems}
                                                setViewModal={setViewModal}
                                                setSelectedDataID={setSelectedDataID}
                                                setSelectedData={setSelectedData}
                                              />
                                            ) : (
                                              ""
                                            )
            }
          </DataTableBody>
          <div className="card-inner">
            {tableData?.length > 0 ? (
              <PaginationComponent
                noDown
                itemPerPage={itemPerPage}
                totalItems={tableData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No Data Found</span>
              </div>
            )}
          </div>
        </DataTable>
      </Block>
      {/* Modal For Viewing Selected Row Details */}
      <ModalDetails
        modalOpen={openViewModal}
        setModalOpen={setViewModal}
        modalTitle={
          type === app_consts.ONBOARD_USER_PROPERTY
            ? `${detail && `${detail.property_name}'s Details`}`
            : type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE
              ? `Company Representative Details`
              : type === app_consts.WAREHOUSE_INVENTORY_BRANCHES_LIST
                ? `Warehouse Inventory Branch Details`
                : type === app_consts.INVENTORY_HISTORY_LIST
                  ? `Warehouse Inventory History`
                  : type === app_consts.ONBOARD_COMPANY_SEARCH
                    ? `Onboard Company User Search`
                    : type === app_consts.GRN_HISTORY
                      ? `GRN History` : ""
        }
        modalType={type}
        modalData={detail}
      />
    </>
  );
};

export default ReadOnlyTable;
