import React, { useEffect, useState } from 'react';
import { Button, Col, Icon, RSelect, Row } from '../../../../../../components/Component';
import { Alert, Spinner } from 'reactstrap';
import TextInput from '../../../../../components/inputs/TextInput';
import ListText from '../../../text/ListText';
import { fail_product_list, loading_product_list, reset_product_list, success_product_list } from '../../../../../../reducers/dashboard_reducers/products/product_list_Slice';
import { CREATE_GOODS_RECEIVED_NOTE_PRODUCT_ACCESS_DENIED_MSG, CREATE_GOODS_RECEIVED_NOTE_PRODUCT_ERR_MSG, CREATE_GOODS_RECEIVED_NOTE_PRODUCT_SUCCESS_MSG, RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG, RETRIEVE_PRODUCTS_LIST_ERR_MSG, RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG, RETRIEVE_WAREHOUSE_STORAGE_LIST_ACCESS_DENIED_MSG, RETRIEVE_WAREHOUSE_STORAGE_LIST_ERR_MSG, RETRIEVE_WAREHOUSE_STORAGE_LIST_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import { CREATE_GOODS_RECEIVED_NOTE_PRODUCT_API, RETRIEVE_ACTIVE_PRODUCT_LIST, RETRIEVE_SPECIFC_BRANCH_STORAGE_LIST_BY_STATUS_ID_API } from '../../../../../../api/api_data';
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { messageToast, rSelectMapFun } from '../../../../../../utils/Utils';
import { useSelector } from 'react-redux';
import * as app_consts from "../../../../../../constants/react_app_consts";
import { convertPackagingUnits, retreive_rSelectVariables } from '../../../../../../utils/functionalUtils';
import { fail_retrieve_warehouse_storages_list, loading_retrieve_warehouse_storages_list, reset_retrieve_warehouse_storages_list, success_retrieve_warehouse_storages_list } from '../../../../../../reducers/dashboard_reducers/warehouse/retrieve_warehouse_storages_list_Slice';
import { fail_add_goods_received_products, loading_add_goods_received_products, reset_add_goods_received_products, success_add_goods_received_products } from '../../../../../../reducers/dashboard_reducers/goods_received/add_goods_received_products_Slice';

const initialState = { product_id: "", product_type: "", product: "", warehouse_storage_id: "", warehouse_storage: "", total_capacity_selected_measurement: "", packaging_total_number: "", total_capacity_in_mt: "", uom: "", goods_received_id: "", uom_label: "", warehouse_storage_label: "" };

const AddGoodsReceivedProductsModal = ({ page_data, openModal, successFunction }) => {

    const [step, setStep] = useState(1);
    const [errorVal, setError] = useState("");
    const [formData, setFormData] = useState(initialState);
    const [productTypeList, setProductTypeList] = useState([]);
    const [productListLoading, setProductListLoading] = useState(true);
    const [productsList, setProductsList] = useState([]);
    const [uom, setUom] = useState([]);
    const [warehousestorageslist, setWarehouseStoragesList] = useState([]);
    const [addLoading, setAddLoading] = useState(false);
    const dispatch = useDispatch();
    const [api_data, setRetrievedData] = useState([]);
    const [product_data, setProductData] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState();

    //     // Useffect to Run when the Modal Opens
    useEffect(() => {
        // setRetrievedData(page_data);
        if (openModal && page_data)
        {
            // Retrieve Product List
            dispatch(
                employee_dashboard_actions_helper(
                    loading_product_list,
                    success_product_list,
                    fail_product_list,
                    RETRIEVE_PRODUCTS_LIST_ERR_MSG,
                    RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG,
                    app_consts.GET,
                    RETRIEVE_ACTIVE_PRODUCT_LIST,
                    null,
                    RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG
                )
            );

            // Retrieve Warehouse Storage List
            dispatch(
                employee_dashboard_actions_helper(
                    loading_retrieve_warehouse_storages_list,
                    success_retrieve_warehouse_storages_list,
                    fail_retrieve_warehouse_storages_list,
                    RETRIEVE_WAREHOUSE_STORAGE_LIST_ERR_MSG,
                    RETRIEVE_WAREHOUSE_STORAGE_LIST_SUCCESS_MSG,
                    app_consts.POST,
                    RETRIEVE_SPECIFC_BRANCH_STORAGE_LIST_BY_STATUS_ID_API,
                    { branch_location_id: page_data?.continue_processing_data?.branch_location_id, warehouse_storage_status_id: app_consts.ACTIVATE_ACCOUNT_ID },
                    RETRIEVE_WAREHOUSE_STORAGE_LIST_ACCESS_DENIED_MSG,
                    app_consts.MAP,
                    app_consts.BRANCH_STORAGES_TYPES_MAP
                )
            );
        }
    }, [openModal, page_data]);

    //Listen to State of Product List
    const product_list = useSelector((state) => state.product_list.product_list);

    useEffect(() => {
        if (product_list.status !== app_consts.IDLE)
        {
            if (product_list.status === app_consts.LOADING)
            {
                setProductListLoading(true);
            } else
            {
                setProductListLoading(false);
                if (product_list.status === app_consts.FAIL)
                {
                    messageToast(product_list.message, app_consts.FAIL);
                } else if (product_list.status === app_consts.SUCCESS)
                {
                    setProductData(product_list.data);
                }
                dispatch(reset_product_list());
            }
        }
    }, [product_list]);


    const warehouse_storages_list = useSelector((state) => state.retrieve_warehouse_storages_list.retrieve_warehouse_storages_list);

    useEffect(() => {
        if (warehouse_storages_list.status !== app_consts.IDLE)
        {
            if (warehouse_storages_list.status === app_consts.LOADING)
            {
                // setLoading(true);
            } else
            {
                // setLoading(false);
                if (warehouse_storages_list.status === app_consts.FAIL)
                {
                    messageToast(warehouse_storages_list.message, app_consts.FAIL);
                } else if (warehouse_storages_list.status === app_consts.SUCCESS)
                {
                    if (warehouse_storages_list.data?.length === 0)
                    {
                        messageToast("No Warehouse Storage Sites Found.", app_consts.FAIL);
                    }
                    else
                    {
                        setWarehouseStoragesList(warehouse_storages_list.data);
                    }
                }
                dispatch(reset_retrieve_warehouse_storages_list());
            }
        }
    }, [warehouse_storages_list]);

    // Product Data listener
    useEffect(() => {
        if (product_data) 
        {
            handleProductType();
        }
    }, [product_data]);

    const handleProductType = async () => {
        let mappedData = [];


        await Promise.all(
            product_data?.map(async (data_to_map) => {
                const { label, value } = retreive_rSelectVariables(app_consts.PRODUCT_TYPE_MAP, data_to_map);
                const result = await rSelectMapFun(label, value);
                mappedData.push(result);
            })
        );

        setProductTypeList(mappedData);
    };

    // Handle Preview
    const handlePreview = () => {
        setError("");
        setStep(2);
    };

    // Handle Product Type Change
    const handleProductTypeChange = async (value, label) => {
        if (value && label) 
        {
            setFormData({ ...formData, ["product_type"]: label, ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "" });
            let mappedData = [];

            const filtereredData = product_data?.filter((data) => data.product_type_id === value);

            setSelectedProducts(filtereredData[0]?.products);

            await Promise.all(
                filtereredData[0]?.products?.map(async (data_to_map) => {
                    const { label, value } = retreive_rSelectVariables(app_consts.PRODUCTS_MAP, data_to_map);
                    const result = await rSelectMapFun(label, value);
                    mappedData.push(result);
                })
            );
            setProductsList(mappedData);
        }
        else
        {
            setFormData({ ...formData, ["product_type"]: "", ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "" });
        }
    };

    // Handle Product Change
    const handleProductChange = async (value, label) => {
        if (value && label) 
        {
            setFormData({ ...formData, ["product_id"]: value, ["product"]: label, ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "" });
            let mappedData = [];

            const filtereredData = selectedProducts?.filter((data) => data.product_id === value);

            await Promise.all(
                filtereredData?.map(async (data_to_map) => {
                    // Map Pum Liist
                    data_to_map?.pum_list?.map(async (map) => {
                        map?.uom_description?.map(async (last_data) => {
                            const { label, value } = retreive_rSelectVariables(app_consts.UOM_MAP, last_data);
                            const result = await rSelectMapFun(label, value);
                            mappedData.push(result);
                        });
                    });
                })
            );
            setUom(mappedData);
        }
        else
        {
            setFormData({ ...formData, ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "" });
        }
    };

    // Handle Unit of Measurem Chanage
    const handleUomChange = async (value, label) => {
        if (value && label) 
        {
            setFormData({ ...formData, ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: value, ["uom_label"]: label });
            let mappedData = [];

            // const filtereredData = api_data?.filter((data) => data.product_id === value);

            // await Promise.all(
            //     filtereredData?.map(async (data_to_map) => {
            //         const { label, value } = retreive_rSelectVariables(app_consts.UOM_MAP, data_to_map);
            //         const result = await rSelectMapFun(label, value);
            //         mappedData.push(result);
            //     })
            // );
            // setUom(mappedData);
        }
        else
        {
            setFormData({ ...formData, ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "" });
        }
    };

    // Handle Storage Type Change
    const handleStorageTypeChange = (value, label) => {
        if (value && label) 
        {
            setFormData({ ...formData, ["warehouse_storage_id"]: value, ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: label });
        }
        else
        {
            setFormData({ ...formData, ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "" });
        }
    };

    // Handle Change
    const handleChange = (e) => {
        if (e.target.name === "packaging_total_number") 
        {
            let converted_data = { total_capacity_in_mt: "", total_capacity_selected_measurement: "" };
            const result = convertPackagingUnits(e.target.value, formData.uom_label);
            if (result?.original)
            {
                converted_data.total_capacity_selected_measurement = result?.original;
            }
            if (result?.metricTonnes)
            {
                converted_data.total_capacity_in_mt = result?.metricTonnes;
            }

            setFormData({ ...formData, ["packaging_total_number"]: e.target.value, ["total_capacity_in_mt"]: converted_data.total_capacity_in_mt, ["total_capacity_selected_measurement"]: converted_data.total_capacity_selected_measurement });
        }
    };

    const handleOnblur = (e) => {
        if (e.target.name === "total_capacity_in_mt")
        {
            if (e.target.value)
            {
                setFormData({ ...formData, ["total_capacity_in_mt"]: parseFloat(e.target.value).toFixed(2) });
            }
            else
            {
                setFormData({ ...formData, ["total_capacity_in_mt"]: "" });
            }
        }
        else
        {
            if (e.target.value)
            {
                setFormData({ ...formData, [e.target.name]: parseFloat(e.target.value).toFixed(2) });
            }
            else
            {
                setFormData({ ...formData, [e.target.name]: "" });
            }
        }
    };

    //     // Handle Submit
    const handleSubmit = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_add_goods_received_products,
                success_add_goods_received_products,
                fail_add_goods_received_products,
                CREATE_GOODS_RECEIVED_NOTE_PRODUCT_ERR_MSG,
                CREATE_GOODS_RECEIVED_NOTE_PRODUCT_SUCCESS_MSG,
                app_consts.POST,
                CREATE_GOODS_RECEIVED_NOTE_PRODUCT_API,
                { goods_received_id: page_data?.continue_processing_data?.goods_received_id, product_id: formData.product_id, warehouse_storage_id: formData.warehouse_storage_id, unit_measurement_id: formData.unit_measurement_id, total_capacity_selected_measurement: formData.total_capacity_selected_measurement, packaging_total_number: formData.packaging_total_number, total_capacity_in_mt: formData.total_capacity_in_mt, uom: formData.uom },
                CREATE_GOODS_RECEIVED_NOTE_PRODUCT_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to Reducer Changes
    const add_grn_products_listener = useSelector((state) => state.add_goods_received_products.add_goods_received_products);
    //
    useEffect(() => {
        if (add_grn_products_listener.status !== app_consts.IDLE)
        {
            if (add_grn_products_listener.status === app_consts.LOADING)
            {
                setAddLoading(true);
            } else
            {
                setAddLoading(false);
                if (add_grn_products_listener.status === app_consts.FAIL)
                {
                    setError(add_grn_products_listener.message);
                    messageToast(add_grn_products_listener.message, app_consts.FAIL);
                } else if (add_grn_products_listener.status === app_consts.SUCCESS)
                {
                    messageToast(CREATE_GOODS_RECEIVED_NOTE_PRODUCT_SUCCESS_MSG, app_consts.SUCCESS);
                    successFunction(add_grn_products_listener.data);
                }
                dispatch(reset_add_goods_received_products());
            }
        }
    }, [add_grn_products_listener]);

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                {step === 2 && (
                    <div
                        className="nk-block-head-sub mb-2"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault();
                            setStep(1);
                            setFormData(initialState);
                        }}
                    >
                        <Icon name="arrow-left" />
                        <span>back</span>
                    </div>
                )}
            </div>
            {errorVal && (
                <div className="mb-3 w-100">
                    <Alert color="danger" className="alert-icon">
                        <Icon name="alert-circle" /> {errorVal}{" "}
                    </Alert>
                </div>
            )}
            {step === 1 ? (
                <>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Row className="g-4">
                            <div className="form-label-group">
                                <label className="form-label text-soft fs-22px">Product Details</label>
                            </div>
                            <Col size={6} className="my-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">
                                        Product Type
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                options={productTypeList}
                                                className="w-100"
                                                placeholder="Search"
                                                id="product_type"
                                                isLoading={productListLoading}
                                                onChange={(e) => { handleProductTypeChange(e.value, e.label); }}
                                                isDisabled={productListLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col size={6} className="my-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">
                                        Product
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                options={productsList}
                                                className="w-100"
                                                placeholder="Search"
                                                id="product_id"
                                                onChange={(e) => { handleProductChange(e.value, e.label); }}
                                                isDisabled={!formData.product_type}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col size={6} className="my-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="uom">
                                        Unit of Measure
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                options={uom}
                                                className="w-100"
                                                placeholder="Search"
                                                id="uom"
                                                onChange={(e) => { handleUomChange(e.value, e.label); }}
                                                isDisabled={!formData.product_id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col size={6} className="my-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="warehouse_storage_id">
                                        Warehouse Storage Type
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                options={warehousestorageslist}
                                                className="w-100"
                                                placeholder="Search"
                                                id="warehouse_storage_id"
                                                onChange={(e) => { handleStorageTypeChange(e.value, e.label); }}
                                                isDisabled={!formData.uom}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <div className="form-label-group">
                                <label className="form-label text-soft fs-22px">Measurement Details</label>
                            </div>

                            <TextInput
                                parentClassName="mt-2"
                                label="Package Count"
                                id_val="packaging_total_number"
                                type="number"
                                value={formData?.packaging_total_number}
                                colSize="6"
                                name="packaging_total_number"
                                disabled={!formData.warehouse_storage_id}
                                handleChange={handleChange}
                            />
                            <TextInput
                                parentClassName="mt-2"
                                label={`Total Capacity ${formData.uom_label ? `(${formData.uom_label})` : ""}`}
                                id_val="total_capacity_selected_measurement"
                                type="number"
                                value={formData?.total_capacity_selected_measurement}
                                colSize="6"
                                name="total_capacity_selected_measurement"
                                disabled={true}
                                handleChange={handleChange}
                                onBlur={handleOnblur}
                            />
                            <TextInput
                                parentClassName="mt-2"
                                label="Goods Received Capacity (MT)"
                                id_val="total_capacity_in_mt"
                                maxlength="160"
                                type="number"
                                value={formData?.total_capacity_in_mt}
                                colSize="6"
                                name="total_capacity_in_mt"
                                disabled={true}
                                handleChange={handleChange}
                                onBlur={handleOnblur}
                            />
                            <Col xl="12" className="d-flex justify-content-center">
                                <Button
                                    onClick={handlePreview}
                                    size="lg"
                                    type="submit"
                                    color="primary"
                                    disabled={!(formData.product_id && formData.warehouse_storage_id && formData.total_capacity_selected_measurement && formData.packaging_total_number && formData.total_capacity_in_mt && formData.uom)}
                                >
                                    Preview Product and Add
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </>
            ) : step === 2 ? (
                <>
                    <ListText dataItem="data-item mySmall" label="Product Name" value={formData.product} />
                    <ListText dataItem="data-item mySmall" label="Product Type" value={formData.product_type} />
                    <ListText dataItem="data-item mySmall" label="Warehouse Storage Type" value={formData.warehouse_storage_label} />
                    <ListText dataItem="data-item mySmall" label="Unit of Measurement" value={formData.uom_label} />
                    <ListText dataItem="data-item mySmall" label="Packaging Total Number" value={formData.packaging_total_number} />
                    <ListText dataItem="data-item mySmall" label={`Total Capacity ${formData.unit_measurement ? `(${formData.unit_measurement})` : ""}`} value={formData.total_capacity_selected_measurement} />
                    <ListText dataItem="data-item mySmall" label="Goods Received Capacity (MT)" value={formData.total_capacity_in_mt} />
                    <Col xl="12" className="d-flex justify-content-end mt-4 mr-2">
                        <Button onClick={handleSubmit} disabled={addLoading} color="primary">
                            {addLoading ? <Spinner size="sm" color="light" /> : "Add Product"}
                        </Button>
                    </Col>
                </>
            )
                :
                ""
            }

        </>
    );
};

export default AddGoodsReceivedProductsModal;