import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as app_consts from "../../../../../constants/react_app_consts";
import AdjustInventoryModel from './warehouse_inventory_modal_data/AdjustInventoryModel';

const WarehouseInventoryModel = ({
 openModal,
 setOpenModal,
 type,
 modalTitle,
 modalDescription,
 succFunc,
 size,
 data,
 openOtherModalFunc,
 successFunction }
) => {

 const [modal, setModal] = useState(false);
 const [modalLoading, setModalLoading] = useState(false);
 const [modal_type, setModalType] = useState(type);

 useEffect(() => {
  setModal(openModal);
  setModalType(type);
 }, [openModal]);

 const toggleModal = () => {
  setOpenModal(!modal);
 };

 return (
  <>
   <Modal
    isOpen={modal}
    // toggle={!modalLoading ? toggleModal : ""}
    className="modal-dialog-centered"
    size={size ? size : "lg"}
   >
    <ModalHeader toggle={!modalLoading ? toggleModal : ""}>{modalTitle}</ModalHeader>
    <ModalBody>
     {
      modal_type === app_consts.ADJUST_INVENTORY_MODAL ?
       <>
        <AdjustInventoryModel data={data} modal={modal} />
       </>
       :
       <>

       </>
     }
    </ModalBody>
   </Modal>
  </>
 );
};

export default WarehouseInventoryModel;