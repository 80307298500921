import moment from "moment";
import { DataTableItem } from "../../../../../components/Component";
import TableRow from "../table_components/TableRow";
import * as app_consts from "../../../../../constants/react_app_consts";
import { underscore_words } from "../../../../../utils/functionalUtils";

export const UserListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.member_access_id}>
                <TableRow rowLink={`/dashboard/user/${item.phone_number}`} item={item} avatar type="rowMain" />
                <TableRow item={item.phone_number} type="rowNormal" />
                <TableRow item={item.email_address} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                        ? "warning"
                        : item.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  view_details
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Branch Location Rows
export const BranchLocationRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.branch_location_id}>
                <TableRow rowLink={`/dashboard/admin/branch_locations/${item?.branch_location_id}/${underscore_words(item?.branch_area)}`} item={item} table_type={type} type="rowMain" />
                <TableRow
                  item={
                    item.branch_physical_address
                      ? item.branch_physical_address.length > 20
                        ? `${item.branch_physical_address.substring(0, 20)}...`
                        : item.branch_area
                      : ""
                  }
                  type="rowNormal"
                />
                <TableRow item={item.branch_mobile_number} type="rowNormal" />
                <TableRow item={item.province_name} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                        ? "warning"
                        : item.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  table_type={type}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const ProductRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.branch_location_id}>
                <TableRow item={item} type="rowMain" table_type={type} />
                <TableRow
                  item={
                    item.product_type
                      ? item.product_type.length > 20
                        ? `${item.product_type.substring(0, 20)}...`
                        : item.product_type
                      : ""
                  }
                  type="rowNormal"
                />
                <TableRow
                  item={
                    item?.pum_list ?
                      item?.pum_list?.length == 1 ? item?.pum_list[0]?.unit_measurement_abbrev :
                        item?.pum_list?.length > 1 ? `${item?.pum_list[0]?.unit_measurement_abbrev}, ${item?.pum_list[1]?.unit_measurement_abbrev}`
                          : ""
                      :
                      ""
                  }
                  type="rowNormal"
                />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                        ? "warning"
                        : item.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  table_type={type}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const ProductTypeRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.branch_location_id}>
                <TableRow item={item} table_type={type} type="rowMain" />
                <TableRow item={item.products_count} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                        ? "warning"
                        : item.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const UnitMeasurementRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.unit_measurement_id}>
                <TableRow item={item} type="rowMain" table_type={type} />
                <TableRow item={item.unit_measurement_abbrev} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                        ? "warning"
                        : item.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Employee List Rows
export const EmployeeListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.employee?.employee_id}>
                <TableRow item={item} type="rowMain" table_type={type} />
                <TableRow item={`${item?.user?.first_name} ${item?.user?.surname}`} type="rowNormal" />
                <TableRow item={item?.employee_role?.role_name} type="rowNormal" />
                <TableRow item={item?.employee_department?.department_name.toUpperCase()} type="rowNormal" />
                <TableRow item={item?.branch_location?.branch_area} type="rowNormal" />
                <TableRow
                  item={item?.user_account?.account_status}
                  type="rowStatus"
                  item_color={
                    item?.user_account?.account_status_id &&
                    (item?.user_account?.account_status_id === 1
                      ? "success"
                      : item?.user_account?.account_status_id === 2
                        ? "warning"
                        : item?.user_account?.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};


// Goods Received Weigh Bridge Ticket List Rows
export const GoodsReceivedWeighBridgeTicketListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.goods_received?.goods_received_ref_number}>
                <TableRow rowLink={`/dashboard/goods_received/${item?.goods_received?.goods_received_ref_number}`} item={item} type="rowMain" table_type={type} />
                <TableRow
                  item={
                    item.user_details?.name
                      ? item.user_details?.name.length > 20
                        ? `${item.user_details?.name.substring(0, 20)}...`
                        : item.user_details?.name
                      : "---"
                  }
                  type="rowNormal"
                />
                <TableRow item={item?.user_account?.unique_identification_number || "--"} type="rowNormal" />
                <TableRow item={item?.weigh_bridge_ticket_type?.weigh_bridge_ticket_type.toUpperCase() || "--"} type="rowNormal" />
                <TableRow item={item?.weigh_bridge_ticket?.truck_licence_plate || "--"} type="rowNormal" />
                <TableRow item={item?.goods_received?.inserted_at ? moment(item?.goods_received?.inserted_at).format("YYYY-MM-DD h:mm:ss") : "--"} type="rowNormal" />
                <TableRow
                  item={item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status || "--"}
                  type="rowStatus"
                  item_color={item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE ? "success" : "warning"}
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Goods Received Weigh Bridge Ticket List Rows
export const WarehouseInventoryListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.product_id}>
                <TableRow rowLink={`/dashboard/warehouse_inventory/${item?.product_id}`} item={item} type="rowMain" table_type={type} />
                <TableRow item={item?.product_type} type="rowNormal" />
                <TableRow item={item?.total_warehouse_store ? `${parseFloat(item?.virtual_store_capacity).toFixed(2)}` : "0.00"} type="rowNormal" />
                <TableRow item={item?.total_warehouse_store ? `${parseFloat(item?.physical_store_capacity).toFixed(2)}` : "0.00"} type="rowNormal" />
                <TableRow item={item?.total_warehouse_store ? `${parseFloat(item?.total_warehouse_store).toFixed(2)}` : "0.00"} type="rowNormal" />
                <TableRow
                  item={item.account_status}
                  type="rowStatus"
                  item_color={
                    item.account_status_id &&
                    (item.account_status_id === 1
                      ? "success"
                      : item.account_status_id === 2
                        ? "warning"
                        : item.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Goods Received Weigh Bridge Ticket List Rows
export const DeliveryNoteTicketListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.delivery_note?.delivery_note_ref_number}>
                <TableRow rowLink={`/dashboard/delivery_notes/${item?.delivery_note?.delivery_note_ref_number}`} item={item} type="rowMain" table_type={type} />
                <TableRow
                  item={
                    item.user_details?.name
                      ? item.user_details?.name.length > 20
                        ? `${item.user_details?.name.substring(0, 20)}...`
                        : item.user_details?.name
                      : "---"
                  }
                  type="rowNormal"
                />
                <TableRow item={item?.user_account?.unique_identification_number || "--"} type="rowNormal" />
                <TableRow item={item?.weigh_bridge_ticket_type?.weigh_bridge_ticket_type.toUpperCase() || "--"} type="rowNormal" />
                <TableRow item={item.user_property?.property_area
                  ? item.user_property.length > 20
                    ? `${item.user_property?.property_area?.substring(0, 20)}...`
                    : item.user_property?.property_area
                  : ""} type="rowNormal" />
                <TableRow item={item?.weigh_bridge_ticket?.truck_licence_plate || "--"} type="rowNormal" />
                <TableRow item={item?.delivery_note?.created_date ? moment(item?.delivery_note?.created_date).format("YYYY-MM-DD h:mm:ss") : "--"} type="rowNormal" />
                <TableRow
                  item={item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status || "--"}
                  type="rowStatus"
                  item_color={item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE || item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_DELIVERED ? "success" : "warning"}
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Client Goods Received List Rows
export const ClientGoodsReceivedListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.goods_received?.goods_received_ref_number}>
                <TableRow rowLink={`/customer/goods_received/${item?.goods_received?.goods_received_ref_number}`} item={item} type="rowMain" table_type={type} />
                <TableRow item={item?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no} type="rowNormal" />
                <TableRow item={item?.weigh_bridge_ticket?.transporter_name} type="rowNormal" />
                <TableRow item={item?.weigh_bridge_ticket?.truck_licence_plate} type="rowNormal" />
                <TableRow item={item?.goods_received?.date_created ? moment(item?.goods_received?.date_created).format("YYYY-MM-DD h:mm:ss") : "--"} type="rowNormal" />
                <TableRow
                  item={item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status}
                  type="rowStatus"
                  item_color={item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE ? "success" : "warning"}
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>


            </>
          );
        })}
    </>
  );
};

// Customer User Account List Rows
export const CustomerUserAccountListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {

  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.user_account?.unique_identification_number}>
                <TableRow rowLink={`/dashboard/user/accounts/customer/${item?.user_account?.username}`} item={item} type="rowMain" table_type={type} />
                <TableRow item={item?.user?.first_name} type="rowNormal" />
                <TableRow item={item?.user?.surname} type="rowNormal" />
                <TableRow item="Customer" type="rowNormal" />
                <TableRow item={item?.user_account?.date_created ? moment(item?.user_account?.date_created).format("YYYY-MM-DD h:mm:ss") : "--"} type="rowNormal" />
                <TableRow
                  item={item?.account_status?.account_status}
                  type="rowStatus"
                  item_color={item?.account_status?.account_status_id === app_consts.ACTIVATE_ACCOUNT_ID ? "success" : "warning"}
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>


            </>
          );
        })}
    </>
  );
};

// Customer User Account List Rows
export const ClientDeliveryNoteListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.delivery_note?.delivery_note_ref_number}>
                <TableRow rowLink={`/customer/delivery_notes/${item?.delivery_note?.delivery_note_ref_number}`} item={item} type="rowMain" table_type={type} />
                <TableRow item={item?.user_property?.property_area} type="rowNormal" />
                <TableRow item={item?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no} type="rowNormal" />
                <TableRow item={item?.truck?.truck_license_plate.toUpperCase()} type="rowNormal" />
                <TableRow item={item?.delivery_note?.created_date ? moment(item?.delivery_note?.created_date).format("YYYY-MM-DD h:mm:ss") : "--"} type="rowNormal" />
                <TableRow
                  item={item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status}
                  type="rowStatus"
                  item_color={item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE || item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_DELIVERED ? "success" : "warning"}
                />
                {/* Options */}
                {/* <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                /> */}
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Company User Account List Rows
export const CompanyUserAccountListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {
  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.user_account?.unique_identification_number}>
                <TableRow rowLink={`/dashboard/user/accounts/company/${item?.user_account?.username}`} item={item} type="rowMain" table_type={type} />
                <TableRow item={item?.company?.company_name} type="rowNormal" />
                <TableRow item={item?.company?.company_email_address} type="rowNormal" />
                <TableRow item={item?.company?.company_mobile_number} type="rowNormal" />
                <TableRow item="Company" type="rowNormal" />
                <TableRow item={item?.user_account?.date_created ? moment(item?.user_account?.date_created).format("YYYY-MM-DD h:mm:ss") : "--"} type="rowNormal" />
                <TableRow
                  item={item?.account_status?.account_status}
                  type="rowStatus"
                  item_color={item?.account_status?.account_status_id === app_consts.ACTIVATE_ACCOUNT_ID ? "success" : "warning"}
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>


            </>
          );
        })}
    </>
  );
};

// Truck Driver User Account List Rows
export const TruckDriverUserAccountListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {

  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.user_account?.unique_identification_number}>
                <TableRow rowLink={`/dashboard/user/accounts/truck_driver/${item?.user_account?.username}`} item={item} type="rowMain" table_type={type} />
                <TableRow item={item?.user?.first_name} type="rowNormal" />
                <TableRow item={item?.user?.surname} type="rowNormal" />
                <TableRow item="Truck Driver" type="rowNormal" />
                <TableRow item={item?.user_account?.date_created ? moment(item?.user_account?.date_created).format("YYYY-MM-DD h:mm:ss") : "--"} type="rowNormal" />
                <TableRow
                  item={item?.account_status?.account_status}
                  type="rowStatus"
                  item_color={item?.account_status?.account_status_id === app_consts.ACTIVATE_ACCOUNT_ID ? "success" : "warning"}
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>


            </>
          );
        })}
    </>
  );
};

// Employee Departments and Roles List Rows
export const EmpDeptRolesListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {

  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.employee_department?.department_id}>
                <TableRow rowLink={`/dashboard/admin/departments_and_roles/${item?.employee_role?.role_id}/${underscore_words(item?.employee_role?.role_name)}`} item={item} type="rowMain" table_type={type} />
                <TableRow item={item?.employee_department?.department_name?.toUpperCase()} type="rowNormal" />
                <TableRow item={item?.num_emp} type="rowNormal" />
                <TableRow item={item?.num_actions} type="rowNormal" />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>


            </>
          );
        })}
    </>
  );
};
// Employee Departments and Roles List Rows
export const LoadingOrderListRows = ({
  data,
  currentItems,
  rowOptionsData,
  amendFunction,
  setOpendModalDetails,
  setSelectedData,
  type,
}) => {

  return (
    <>
      {data.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.loading_order_ref_no}>
                <TableRow rowLink={`/dashboard/loading_orders/${item?.loading_order_ref_no}`} item={item} type="rowMain" table_type={type} />
                <TableRow item={item?.owner || "---"} type="rowNormal" />
                <TableRow item={item?.contact_person} type="rowNormal" />
                <TableRow item={item?.contact_mobile} type="rowNormal" />
                <TableRow item={item?.loading_order_product_info?.products_list?.length || 0} type="rowNormal" />
                <TableRow
                  item={item?.status || "---"}
                  type="rowStatus"
                  item_color={item?.status?.toLowerCase() === app_consts.PROCESSING_COMPLETE?.toLowerCase() ? "success" : "warning"}
                />
                {/* Options */}
                <TableRow
                  item={item}
                  table_type={type}
                  type="rowOptions"
                  rowOptData={rowOptionsData}
                  amendFunc={amendFunction}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};