import React, { useEffect, useState } from 'react';
import { Alert, Spinner } from 'reactstrap';
import { Button, Col, Icon, Row, RSelect } from '../../../../../../components/Component';
import TextInput from '../../../../../components/inputs/TextInput';
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { fail_create_customer_account, loading_create_customer_account, reset_create_customer_account, success_create_customer_account } from '../../../../../../reducers/dashboard_reducers/user_accounts/create_customer_account_Slice';
import { CREATE_CUSTOMER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG, CREATE_CUSTOMER_USER_ACCOUNT_LIST_ERR_MSG, CREATE_CUSTOMER_USER_ACCOUNT_LIST_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import { CREATE_CUSTOMER_ACCOUNT } from '../../../../../../api/api_data';
import { messageToast } from '../../../../../../utils/Utils';
import { useSelector } from 'react-redux';
import * as app_consts from "../../../../../../constants/react_app_consts";

const AddUserAccountModal = ({ data, successFunction }) => {

 const [errorVal, setError] = useState("");
 const [user_data, setUserData] = useState(data);
 const [addLoading, setAddLoading] = useState(false);
 const dispatch = useDispatch();
 const [selected_account_type, setSelectedAccountType] = useState("");

 const account_types = [{ value: "individual_customer_account", label: "Individual Customer Account" }];

 useEffect(() => {
  setUserData(data);
 }, [data]);

 const handleSubmit = () => {
  setError("");
  setAddLoading(true);
  // Send API Request
  dispatch(
   employee_dashboard_actions_helper(
    loading_create_customer_account,
    success_create_customer_account,
    fail_create_customer_account,
    CREATE_CUSTOMER_USER_ACCOUNT_LIST_ERR_MSG,
    CREATE_CUSTOMER_USER_ACCOUNT_LIST_SUCCESS_MSG,
    app_consts.POST,
    CREATE_CUSTOMER_ACCOUNT,
    { phone_number: user_data.phone_number },
    CREATE_CUSTOMER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG
   )
  );
 };

 // Listen to Reducers For Creating Customer Account
 const create_customer_account = useSelector((state) => state.create_customer_account.create_customer_account);
 //
 useEffect(() => {
  if (create_customer_account.status !== app_consts.IDLE)
  {
   if (create_customer_account.status === app_consts.LOADING)
   {
    setAddLoading(true);
   } else
   {
    setAddLoading(false);
    if (create_customer_account.status === app_consts.FAIL)
    {
     messageToast(create_customer_account.message, app_consts.FAIL);
     setError(create_customer_account.message, app_consts.FAIL);
    } else if (create_customer_account.status === app_consts.SUCCESS)
    {
     messageToast(CREATE_CUSTOMER_USER_ACCOUNT_LIST_SUCCESS_MSG, app_consts.SUCCESS);
     successFunction();
    }
    dispatch(reset_create_customer_account());
   }
  }
 }, [create_customer_account]);

 return (
  <>
   <div className="d-flex justify-content-between mb-2">
    <h5 className="card-title text-center w-100">Add User Account</h5>
   </div>
   {errorVal && (
    <div className="mb-3 w-100">
     <Alert color="danger" className="alert-icon">
      <Icon name="alert-circle" /> {errorVal}{" "}
     </Alert>
    </div>
   )}
   <form
    onSubmit={(e) => {
     e.preventDefault();
    }}
   >
    <Row className="g-4">
     <TextInput
      label="User Name"
      disabled
      required
      type="text"
      id_val="username"
      value={user_data?.middle_name ? `${user_data?.first_name} ${user_data?.middle_name} ${user_data?.surname}` : `${user_data?.first_name} ${user_data?.surname}`}
      colSize="12"
      name="username"
     />
     <Col>
      <div className="form-group">
       <label className="form-label mb-1 text-md" htmlFor="username">
        Account Type
       </label>
       <div className="form-control-wrap align-items-center w-100 d-flex flex-row mb-2 justify-between">
        <div className="w-100">
         <RSelect
          options={account_types}
          className="w-100"
          placeholder="Search"
          id="account_type"
          onChange={(e) => {
           setSelectedAccountType(e.value);
           //   setPreviewData({ ...previewData, ["product_type_name"]: e.label });
          }}
         />
        </div>
       </div>
      </div>
     </Col>
     <Col xl="12" className="d-flex justify-content-end mt-4 mr-2">
      <Button onClick={handleSubmit} disabled={addLoading || selected_account_type !== "individual_customer_account"} color="primary">
       {addLoading ? <Spinner size="sm" color="light" /> : "Add User Account"}
      </Button>
     </Col>
    </Row>
   </form>
  </>
 );
};

export default AddUserAccountModal;