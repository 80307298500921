import React, { useEffect, useState } from 'react';
import { Alert, Spinner } from 'reactstrap';
import { Col, Icon, Row } from '../../../../../../components/Component';
import { useDispatch } from 'react-redux';
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { RETRIEVE_PRODUCT_DETAILS_AT_SPECIFIC_STORAGE_API } from '../../../../../../api/api_data';
import { RETRIEVE_PRODUCT_DETAILS_AT_SPECIFIC_STORAGE_ACCESS_DENIED_MSG, RETRIEVE_PRODUCT_DETAILS_AT_SPECIFIC_STORAGE_ERR_MSG, RETRIEVE_PRODUCT_DETAILS_AT_SPECIFIC_STORAGE_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import { fail_retrieve_product_warehouse_details, loading_retrieve_product_warehouse_details, reset_retrieve_product_warehouse_details, success_retrieve_product_warehouse_details } from '../../../../../../reducers/dashboard_reducers/warehouse/retrieve_product_warehouse_details_Slice';
import { messageToast } from '../../../../../../utils/Utils';
import { useSelector } from 'react-redux';
import * as app_consts from "../../../../../../constants/react_app_consts";
import { BiErrorCircle } from 'react-icons/bi';
import TextInput from '../../../../../components/inputs/TextInput';

const AdjustInventoryModel = ({ data, successFunction, modal }) => {

 const [errorVal, setError] = useState("");
 const [user_data, setUserData] = useState(data);
 const [loading, setLoading] = useState(false);
 const [data_loading, setDataLoading] = useState(true);
 const [data_error, setDataError] = useState("");
 const dispatch = useDispatch();
 const [page_data, setPageData] = useState([]);


 useEffect(() => {
  setUserData(data);
 }, [data]);

 // User Data Check
 useEffect(() => {
  // Dispatch to Retrieve Data for Page
  if (user_data && modal)
  {
   dispatch(
    employee_dashboard_actions_helper(
     loading_retrieve_product_warehouse_details,
     success_retrieve_product_warehouse_details,
     fail_retrieve_product_warehouse_details,
     RETRIEVE_PRODUCT_DETAILS_AT_SPECIFIC_STORAGE_ERR_MSG,
     RETRIEVE_PRODUCT_DETAILS_AT_SPECIFIC_STORAGE_SUCCESS_MSG,
     app_consts.GET,
     `${RETRIEVE_PRODUCT_DETAILS_AT_SPECIFIC_STORAGE_API}/${user_data?.warehouse_storage_id}/${user_data?.product_id}`,
     null,
     RETRIEVE_PRODUCT_DETAILS_AT_SPECIFIC_STORAGE_ACCESS_DENIED_MSG
    )
   );
  }

 }, [user_data]);

 // Listen to Reducer
 const product_warehouse_details = useSelector((state) => state.retrieve_product_warehouse_details.retrieve_product_warehouse_details);
 // 
 useEffect(() => {
  console.log(product_warehouse_details);
  if (product_warehouse_details.status !== app_consts.IDLE)
  {
   if (product_warehouse_details.status === app_consts.LOADING)
   {
    setDataLoading(true);
   } else
   {
    setDataLoading(false);
    if (product_warehouse_details.status === app_consts.FAIL)
    {
     setDataError(product_warehouse_details.message);
    } else if (product_warehouse_details.status === app_consts.SUCCESS)
    {
     setPageData(product_warehouse_details.data);
    }
    dispatch(reset_retrieve_product_warehouse_details());
   }
  }
 }, [product_warehouse_details]);


 return (
  <>
   <div className="d-flex justify-content-between mb-2">
    <h5 className="card-title text-center w-100">Adjust Inventory</h5>
   </div>
   {errorVal && (
    <div className="mb-3 w-100">
     <Alert color="danger" className="alert-icon">
      <Icon name="alert-circle" /> {errorVal}{" "}
     </Alert>
    </div>
   )}
   {
    data_loading ?
     <>
      <div className='w-full p-5 d-flex flex-column justify-content-center align-items-center'>
       <Spinner />
       <span className='mt-2 fs-16px'>
        Loading Storage Information
       </span>
      </div>
     </>
     :
     data_error && data_error.length > 0 ?
      <>
       <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
        <BiErrorCircle className="text-danger fs-3" />
        <p>{data_error}</p>
       </div>
      </>
      :
      <>
       {/* // Add A Reason for Adjustment */}

       <form
        onSubmit={(e) => {
         e.preventDefault();
        }}
       >
        <Row className="g-4 mt-4">
         <TextInput
          label="Product Name"
          required
          type="text"
          id_val="product_name"
          maxlength="100"
          value={page_data?.product?.product_name}
          colSize={6}
          name="product_name"
          disabled
         />
         <TextInput
          label="Storage Type"
          required
          type="text"
          id_val="warehouse_storage_type"
          maxlength="100"
          value={page_data?.warehouse_storage_type?.warehouse_storage_type}
          colSize={6}
          name="warehouse_storage_type"
          disabled
         />
         <TextInput
          label="Inventory Storage capacity (MT)"
          required
          type="text"
          id_val="inventory_storage"
          maxlength="100"
          value={page_data?.total_storage_capacity_for_product || 0.00}
          colSize={6}
          name="inventory_storage"
          disabled
         />

        </Row>
       </form>
      </>

   }
  </>
 );
};

export default AdjustInventoryModel;