import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BackTo, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../../../components/Component';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as app_consts from "../../../../constants/react_app_consts";
import { findModuleAction } from '../../../../utils/functionalUtils';

const PageHeading = ({ title, title_addition, description, backButton, backButtonLink, backButtonText, backToText, backToLink, headingRightData, type, showDropDown, pageHeadingActions, data, componentData }) => {
    const [sm, updateSm] = useState(false);

    const [title_addon, setTitleAddOn] = useState("");
    const [dropDownData, setDropDownData] = useState(headingRightData);
    const [page_type, setPageType] = useState(type);
    const [dropDownButton, setDropDownButton] = useState(showDropDown);
    const [page_data, setPageData] = useState(data);
    const [compData, setCompData] = useState(componentData);

    //
    useEffect(() => {
        setTitleAddOn(title_addition);
        setPageType(type);
        setDropDownButton(showDropDown);
        setPageData(data);
    }, [title_addition, type, showDropDown, data]);

    useEffect(() => {
        setCompData(componentData);
    }, [componentData]);

    //
    useEffect(() => {
        setDropDownData(headingRightData);
    }, [headingRightData]);

    return (
        <>
            <BlockHead size="sm" className="mt-3">
                <BlockBetween>
                    <BlockHeadContent>
                        {
                            backToText && (
                                <BackTo link={backToLink} icon="arrow-left">
                                    {backToText}
                                </BackTo>
                            )
                        }
                        <BlockTitle page tag="h3">
                            {title} <strong className="text-primary small">{title_addon && (`/ ${title_addon}`)}</strong>
                        </BlockTitle>
                        <BlockDes className="text-soft">
                            <p>{description}</p>
                        </BlockDes>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <Button
                                className={`btn-icon btn-trigger toggle-expand me-n1  ${sm ? "active" : ""} `}
                                onClick={() => updateSm(!sm)}
                            >
                                <Icon name="more-v" />
                            </Button>
                            <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                <ul className="nk-block-tools g-3">
                                    {
                                        dropDownButton && (
                                            <>
                                                {
                                                    page_type === app_consts.DELIVERY_NOTE_DETAILS ?
                                                        <>
                                                            {
                                                                dropDownData?.amend_delivery_note_status && page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE ?
                                                                    <> <li className="preview-item">
                                                                        <UncontrolledDropdown>
                                                                            <div className="btn-group">
                                                                                <DropdownToggle className="dropdown-toggle-split" color="primary">
                                                                                    <span>Amend Delivery Note Status</span>
                                                                                    <Icon name="chevron-down"></Icon>
                                                                                </DropdownToggle>
                                                                            </div>
                                                                            <DropdownMenu className="mt-3">
                                                                                <ul className="link-list-opt">
                                                                                    <li style={{ cursor: "pointer" }}>
                                                                                        <DropdownItem
                                                                                            tag="a"
                                                                                            onClick={(ev) => {
                                                                                                ev.preventDefault();
                                                                                                pageHeadingActions(app_consts.COMP_AMEND_DELIVERY_NOTE_STATUS_ACTIONS, app_consts.WEIGH_BRIDGE_TICKET_IN_TRANSIT);
                                                                                            }}
                                                                                        >
                                                                                            <span>Set In-Transit</span>
                                                                                        </DropdownItem>
                                                                                    </li>
                                                                                </ul>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </li>
                                                                    </>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                dropDownData?.continue_processing_weigh_bridge_ticket && (
                                                                    <>
                                                                        {
                                                                            page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_OUTBOUND || page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_ONBOARDING_GOODS ? (
                                                                                <>
                                                                                    <li>
                                                                                        <Button color="light" outline className="btn-white" onClick={() => pageHeadingActions(app_consts.CONTINUE_PROCESSING_DELIVERY_NOTE_ACTIONS, "")}>
                                                                                            <Icon name="clipboard"></Icon>
                                                                                            <span>Continue Processing Delivery Note</span>
                                                                                        </Button>
                                                                                    </li>
                                                                                </>
                                                                            )
                                                                                :
                                                                                ""
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        page_type === app_consts.SPECIFIC_GOODS_RECEIVED_DETAILS ?
                                                            <>
                                                                <li>
                                                                    <Button color="light" outline className="btn-white" onClick={() => pageHeadingActions(app_consts.CONTINUE_PROCESSING_GOODS_RECEIVED_ACTIONS, "")}>
                                                                        <Icon name="clipboard"></Icon>
                                                                        <span>Continue Processing Goods Received</span>
                                                                    </Button>
                                                                </li>
                                                            </>
                                                            :
                                                            page_type === app_consts.USER_DETAILS ?
                                                                <>
                                                                    <li className="preview-item">
                                                                        <UncontrolledDropdown>
                                                                            <div className="btn-group">
                                                                                <DropdownToggle className="dropdown-toggle-split" color="primary">
                                                                                    <Icon name="chevron-down"></Icon>
                                                                                    <span>Actions</span>
                                                                                </DropdownToggle>
                                                                            </div>
                                                                            <DropdownMenu className="mt-3">
                                                                                <ul className="link-list-opt">
                                                                                    {
                                                                                        ((findModuleAction(app_consts.ASSIGN_CUSTOMER, compData) !== null)) && (
                                                                                            <>
                                                                                                <li style={{ cursor: "pointer" }}>
                                                                                                    <DropdownItem
                                                                                                        tag="a"
                                                                                                        className='cursor'
                                                                                                        onClick={(ev) => {
                                                                                                            ev.preventDefault();
                                                                                                            pageHeadingActions(app_consts.ADD_USER_ACCOUNT_MODAL, "");
                                                                                                        }}
                                                                                                    >
                                                                                                        <span>Add Individual Customer Account</span>
                                                                                                    </DropdownItem>
                                                                                                </li>
                                                                                            </>
                                                                                        )}
                                                                                </ul>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </li>
                                                                </>
                                                                :
                                                                page_type === app_consts.CREATE_PHYSICAL_LOADING_ORDER ?
                                                                    <>
                                                                        {
                                                                            dropDownData?.create_loading_order && (
                                                                                <>
                                                                                    {
                                                                                        page_data?.loading_order_status === app_consts.UNDERGOING_PROCESSING ? (
                                                                                            <>
                                                                                                <li>
                                                                                                    <Button color="light" outline className="btn-white" onClick={() => pageHeadingActions(app_consts.CONTINUE_PROCESSING_LOADING_ORDER_ACTIONS, "")}>
                                                                                                        <Icon name="clipboard"></Icon>
                                                                                                        <span>Continue Processing Loading Order</span>
                                                                                                    </Button>
                                                                                                </li>
                                                                                            </>
                                                                                        )
                                                                                            :
                                                                                            ""
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                    :
                                                                    page_type === app_consts.PRODUCT_WAREHOUSE_INVENTORY_PAGE ?
                                                                        <>
                                                                            <li className="preview-item">
                                                                                <UncontrolledDropdown>
                                                                                    <div className="btn-group">
                                                                                        <DropdownToggle className="dropdown-toggle-split" color="primary">
                                                                                            <Icon name="chevron-down"></Icon>
                                                                                            <span>Actions</span>
                                                                                        </DropdownToggle>
                                                                                    </div>
                                                                                    <DropdownMenu className="mt-3">
                                                                                        <ul className="link-list-opt">
                                                                                            {
                                                                                                ((findModuleAction(app_consts.ASSIGN_CUSTOMER, compData) !== null)) && (
                                                                                                    <>
                                                                                                        <li style={{ cursor: "pointer" }}>
                                                                                                            <DropdownItem
                                                                                                                tag="a"
                                                                                                                className='cursor'
                                                                                                                onClick={(ev) => {
                                                                                                                    ev.preventDefault();
                                                                                                                    pageHeadingActions(app_consts.ADD_USER_ACCOUNT_MODAL, "");
                                                                                                                }}
                                                                                                            >
                                                                                                                <span>Adjust Stock</span>
                                                                                                            </DropdownItem>
                                                                                                        </li>
                                                                                                    </>
                                                                                                )}
                                                                                        </ul>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </li>
                                                                        </>
                                                                        :
                                                                        ""
                                                }

                                            </>
                                        )
                                    }
                                    <li className="nk-block-tools-opt">
                                        {
                                            backButton ?
                                                <>
                                                    <Link to={backButtonLink ? backButtonLink : ""}>
                                                        <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                                                            <Icon name="arrow-left"></Icon>
                                                            <span>{backButtonText ? backButtonText : ""}</span>
                                                        </Button>
                                                        <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                    </Link>
                                                </> : ""
                                        }
                                        {/* <Button color="primary">
                                            <Icon name="reports" />
                                            <span>Reports</span>
                                        </Button> */}

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead >
        </>
    );
};

export default PageHeading;